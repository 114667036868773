import { useAuth0 } from "@auth0/auth0-react";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../../App";

export const Redirect = () => {
    const location = useLocation();
    const { isLoading, user } = useAuth0();
    const { setLoggedInUser, request } = useContext(AppContext);
    const [hasError, setHasError] = useState(null);
    const navigate = useNavigate();
    const appState = location.state;

    useEffect(() => {
        const redirect = async () => {
            // check if sign up or login
            let returnTo = "/error";
            if (appState.operation === "signup") {
                // if sign up create new user
                try {
                    const resp = await request({
                        url: `/signup`,
                        method: "POST",
                        data: {
                            email: user.email,
                            firstName: appState.firstName,
                            lastName: appState.lastName,
                            responderUuid: appState.responderUuid,
                            program: appState.program,
                            flow: appState.flow,
                        },
                    });

                    if (resp.user.isStaff) {
                        returnTo = "/staff/split-members";
                        setLoggedInUser(resp.user);
                        // else navigate to either welcome, application, or dashboard
                    } else if (resp.user.freeze_member && resp.user.freeze_member.status === "failed") {
                        returnTo = "/keep/dashboard";
                        setLoggedInUser(resp.user);
                    } else if (resp.user.freeze_member) {
                        returnTo = "/freeze/welcome";
                        setLoggedInUser(resp.user);
                    } else if (resp.user.intended_parent) {
                        returnTo = "/family/intake";
                    } else {
                        returnTo = "/";
                    }

                    navigate(returnTo, { state: { userID: resp.user.id } });
                } catch (err) {
                    // TODO: handle this error properly after turning off strictmode
                    // console.log(err);
                    setHasError(err);
                }
            } else if (appState.operation === "login") {
                // must be login or refresh login
                try {
                    const resp = await request({
                        url: `/login`,
                        method: "POST",
                    });

                    if (resp.user.isStaff) {
                        returnTo = "/staff/split-members";
                        setLoggedInUser(resp.user);
                        // else navigate to either welcome, application, or dashboard
                    } else if (resp.user.freeze_member) {
                        if (resp.user.freeze_member.status === "failed") {
                            returnTo = "/keep/dashboard";
                            setLoggedInUser(resp.user);
                        } else if (resp.user.appFinished) {
                            returnTo = "/freeze/dashboard";
                            setLoggedInUser(resp.user);
                        } else if (resp.user.isWelcomed) {
                            returnTo = "/freeze/application";
                            setLoggedInUser(resp.user);
                        } else {
                            returnTo = "/freeze/welcome";
                            setLoggedInUser(resp.user);
                        }
                    } else if (resp.user.intended_parent) {
                        if (resp.user.intended_parent.family.status === "matched") {
                            setLoggedInUser(resp.user);
                            if (resp.user.intended_parent.family.multimatch) {
                                returnTo = "/family/donors";
                            } else {
                                returnTo = "/family/checklist";
                            }
                        } else if (resp.user.appFinished) {
                            returnTo = "/family/donors";
                            setLoggedInUser(resp.user);
                        } else {
                            returnTo = "/family/intake";
                        }
                    } else {
                        returnTo = "/";
                    }

                    navigate(returnTo, { state: { userID: resp.user.id } });
                } catch (err) {
                    // TODO: handle this error properly after turning off strictmode
                    // console.log(err);
                    setHasError(err);
                }
            } else if (appState.operation === "activation") {
                // if sign up create new user
                try {
                    const resp = await request({
                        url: `/activate`,
                        method: "POST",
                        data: {
                            email: user.email,
                            activation_token: decodeURIComponent(appState.activationToken),
                        },
                    });

                    setLoggedInUser(resp.user);

                    if (resp.user.isStaff) {
                        returnTo = "/staff/split-members";
                        setLoggedInUser(resp.user);
                        // else navigate to either welcome, application, or dashboard
                    } else if (resp.user.freeze_member) {
                        if (resp.user.freeze_member.status === "failed") {
                            returnTo = "/keep/dashboard";
                            setLoggedInUser(resp.user);
                        } else if (resp.user.appFinished) {
                            returnTo = "/freeze/dashboard";
                            setLoggedInUser(resp.user);
                        } else if (resp.user.isWelcomed) {
                            returnTo = "/freeze/application";
                            setLoggedInUser(resp.user);
                        } else {
                            returnTo = "/freeze/welcome";
                            setLoggedInUser(resp.user);
                        }
                    } else if (resp.user.intended_parent) {
                        if (resp.user.appFinished) {
                            returnTo = "/family/donors";
                            setLoggedInUser(resp.user);
                        } else {
                            returnTo = "/family/intake";
                        }
                    } else {
                        returnTo = "/";
                    }

                    navigate(returnTo, { state: { userID: resp.user.id } });
                } catch (err) {
                    // TODO: handle this error properly after turning off strictmode
                    // console.log(err);
                    setHasError(err);
                }
            } else if (appState.returnTo) {
                navigate(appState.returnTo);
            }
        };

        redirect();
    });

    useEffect(() => {
        if (hasError) {
            throw hasError;
        }
    }, [hasError]);

    if (isLoading) {
        return <div></div>;
    }

    let logo = <img className="pb-2" height="127" width="165" alt="FreezeByCo logo" src={`${process.env.PUBLIC_URL}/images/logo.svg`} />;

    return (
        <div id="inner-body" className={`min-h-screen flex flex-col bg-white bg-cover bg-fixed bg-top`}>
            <header className="sm:py-8 sm:px-10 px-6 py-5  flex items-center bg-primary justify-between">{logo}</header>
            <div id="app" className="pb-10 freeze flex items-center justify-center">
                <div className="absolute inset-0 flex justify-center items-center z-50">
                    <img width={65} height={65} src={`${process.env.PUBLIC_URL}/images/loading_spinner.gif`} alt="Loading..." />
                </div>
                <div role="alert">Authenticating...</div>
            </div>
        </div>
    );
};
