import React from 'react';
import {useCheckout} from '@stripe/react-stripe-js';

const PayButton = () => {
  const {confirm} = useCheckout();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  const handleClick = () => {
    setLoading(true);
    confirm().then((result) => {
        console.log("result: ", result)
      if (result.type === 'error') {
        setError(result.error)
      }
      setLoading(false);
    })
  };

  return (
    <div>
      <button onClick={handleClick} className='button-family-signup font-normal'>
        Hold match
        {loading ? (
          <img className = "inline ml-1" width={28} height={28} src={`${process.env.PUBLIC_URL}/images/loading_spinner.gif`} alt="Loading..." />
        ) : null}
      </button>
      {error && <div>{error.message}</div>}
    </div>
  )
};

export default PayButton;
