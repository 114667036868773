import React, { useContext, useEffect, useState } from "react";
import { DegreeTypesMap, EthnicitiesMap, FriendlySlugTranslation, RaceMap, redirectToFormsort } from "../../../../util/helpers";
import { AppContext } from "../../../../App";

const Profile = ({ user }) => {
    const [appForms, setAppForms] = useState([]);
    const [filteredApps, setFilteredApps] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [flagFilters, setFlagFilters] = useState([]);
    const [flagCounts, setFlagCounts] = useState({
        green: 0,
        yellow: 0,
        orange: 0,
        red: 0,
    });
    const [openAll, setOpenAll] = useState(true);
    const [hasError, setHasError] = useState(null);
    const { loggedInUser, refreshUser, request } = useContext(AppContext);

    useEffect(() => {
        const refresh = async () => {
            await refreshUser();
        };

        if (loggedInUser === null) {
            refresh();
        }
    }, [loggedInUser, refreshUser]);

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const response = await request({
                    url: `/staff/split-members/${user.id}/profile`,
                    method: "GET",
                });

                let { appForms } = response;
                let counts = {
                    green: 0,
                    yellow: 0,
                    orange: 0,
                    red: 0,
                };

                appForms = appForms.map((form) => {
                    form.question_groups = form.question_groups.map((group) => {
                        group.questions.forEach((question) => {
                            if (question.answer && question.answer.flag && counts.hasOwnProperty(question.answer.flag.color)) {
                                counts[question.answer.flag.color]++;
                            }
                        });
                        group.open = true;
                        return group;
                    });
                    form.open = true;
                    return form;
                });

                setFlagCounts(counts);
                setAppForms(appForms);
                setFilteredApps(appForms);
            } catch (error) {
                // TODO: handle this error
                console.error("Error fetching profile:", error);
                setHasError(error);
            }
        };

        fetchProfile();
    }, [user, request]);

    useEffect(() => {
        if (hasError) {
            throw hasError;
        }
    }, [hasError]);

    const handleFlagFilterChange = (color) => {
        const newFilters = flagFilters.includes(color) ? flagFilters.filter((c) => c !== color) : [...flagFilters, color];

        const filtered = filterApps(searchTerm, newFilters);

        setFlagFilters(newFilters);
        setFilteredApps(filtered);
    };

    const handleInputChange = (event) => {
        const search = event.target.value;

        const filtered = filterApps(search, flagFilters);

        setSearchTerm(event.target.value);
        setFilteredApps(filtered);
    };

    const filterApps = (search, flags) => {
        const filtered = structuredClone(appForms).filter((form) => {
            let formOpen = false;
            form.question_groups = form.question_groups.map((group) => {
                group.open = false;
                if (flags.length) {
                    group.open =
                        group.questions.filter(
                            (question) => question.answer && question.answer.flag && flags.includes(question.answer.flag.color) && group.label.toLowerCase().includes(search.toLowerCase())
                        ).length > 0;
                } else {
                    group.open = group.label.toLowerCase().includes(search.toLowerCase());
                    group.open = group.questions.some((question) => question.label.toLowerCase().includes(search.toLowerCase()));
                }

                if (group.open) {
                    formOpen = true;
                }

                return group;
            });

            form.open = formOpen;
            return form;
        });

        return filtered;
    };

    const handleOpenToggle = (id) => {
        const toggled = structuredClone(filteredApps).map((form) => {
            if (form.id === id) {
                form.open = !form.open;
            }

            return form;
        });

        setFilteredApps(toggled);
    };

    const toggleOpenAll = () => {
        const toggled = structuredClone(filteredApps).map((form) => {
            form.open = !openAll;
            return form;
        });

        setFilteredApps(toggled);
        setOpenAll(!openAll);
    };

    const handleStringAnswer = (answer) => {
        if (!answer) {
            return "";
        } else if (answer in FriendlySlugTranslation) {
            return FriendlySlugTranslation[answer];
        } else if (Array.isArray(answer)) {
            return answer
                .map((a) => {
                    let x = a.split("_").join(" ");
                    return x[0].toUpperCase() + x.substring(1);
                })
                .join(", ");
        } else if (answer.includes("_")) {
            return answer
                .split("_")
                .map((word, i) => {
                    if (i === 0) {
                        return word[0].toUpperCase() + word.substring(1);
                    }

                    return word;
                })
                .join(" ");
        } else if (answer.length > 1) {
            return answer[0].toUpperCase() + answer.substring(1);
        }

        return answer;
    };

    const getGroupFlags = (questions) => {
        let flags = [];
        questions.forEach((question, i) => {
            if (question.answer) {
                if (question.answer.flag) {
                    flags.push(
                        <svg
                            key={question.answer.id}
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill={`${question.answer.flag.color === "yellow" ? "gold" : question.answer.flag.color}`}
                            class="bi bi-flag-fill mr-2"
                            viewBox="0 0 16 16"
                        >
                            <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12 12 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A20 20 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a20 20 0 0 0 1.349-.476l.019-.007.004-.002h.001" />
                        </svg>
                    );
                }
            }
        });

        return flags;
    };

    const determineTable = (form) => {
        if (
            form.flow === "freeze-split-application-general-questions" ||
            form.flow === "split-application-physical-attributes" ||
            form.flow === "split-application-skills-education-work" ||
            form.flow === "freeze-split-application-final-acknowledgements"
        ) {
            return (
                <table className="w-full">
                    <thead>
                        <tr>
                            <th className="w-[3%] text-left py-2 border-b border-dashed border-gray-300 px-5">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-flag" viewBox="0 0 16 16">
                                    <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12 12 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A20 20 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a20 20 0 0 0 1.349-.476l.019-.007.004-.002h.001M14 1.221c-.22.078-.48.167-.766.255-.81.252-1.872.523-2.734.523-.886 0-1.592-.286-2.203-.534l-.008-.003C7.662 1.21 7.139 1 6.5 1c-.669 0-1.606.229-2.415.478A21 21 0 0 0 3 1.845v6.433c.22-.078.48-.167.766-.255C4.576 7.77 5.638 7.5 6.5 7.5c.847 0 1.548.28 2.158.525l.028.01C9.32 8.29 9.86 8.5 10.5 8.5c.668 0 1.606-.229 2.415-.478A21 21 0 0 0 14 7.655V1.222z" />
                                </svg>
                            </th>
                            <th className="w-1/3 text-left py-2 border-b border-dashed border-gray-300">Category</th>
                            <th className="w-1/2 text-left py-2 border-b border-dashed border-gray-300">Answer</th>
                        </tr>
                    </thead>
                    <tbody>
                        {form.question_groups.map((group, r) => {
                            if (!group.open) {
                                return null;
                            }

                            const { questions } = group;

                            let flags = getGroupFlags(questions);
                            let answer = [];

                            if (group.label === "Engagement Preferences") {
                                return null;
                            } else if (group.label === "Citizenship") {
                                answer.push(
                                    <span key={questions[0].id}>
                                        {questions[0].answer ? handleStringAnswer(questions[0].answer.answer.value) : null}{" "}
                                        {questions[1].answer ? `, ${handleStringAnswer(questions[1].answer.answer.value)}` : null}
                                    </span>
                                );
                            } else if (group.label === "Legal Name" && questions[0].answer) {
                                answer.push(
                                    <span key={questions[0].id}>
                                        {questions[0].answer.answer.value ? questions[0].answer.answer.value : null} {questions[1].answer.answer.value ? questions[1].answer.answer.value : null}
                                    </span>
                                );
                            } else if (group.label === "Phone number" && questions[0].answer) {
                                answer.push(<div key={questions[0].id}>{questions[0].answer.answer.value}</div>);
                                answer.push(questions[1].answer ? <div key={questions[1].id}>Text consent: {handleStringAnswer(questions[1].answer.answer.value)}</div> : null);
                            } else if (group.label === "Address" && questions[0].answer) {
                                answer.push(
                                    <span key={questions[0].id}>
                                        {questions[0].answer.answer.value.address_1 ? questions[0].answer.answer.value.address_1 : null}
                                        {questions[0].answer.answer.value.address_2 ? `, ${questions[0].answer.answer.value.address_2}` : null}{" "}
                                        {questions[0].answer.answer.value.city ? questions[0].answer.answer.value.city : null}
                                        {questions[0].answer.answer.value.state ? `, ${questions[0].answer.answer.value.state}` : null}
                                        {questions[0].answer.answer.value.country ? `, ${questions[0].answer.answer.value.country}` : null}{" "}
                                        {questions[0].answer.answer.value.postal_code ? questions[0].answer.answer.value.postal_code : null}
                                    </span>
                                );
                            } else if (group.label === "Travel History Europe" && questions[0].answer) {
                                if (questions[0].answer.answer.value === "no") {
                                    answer.push(<span key={questions[0].id}>{handleStringAnswer(questions[0].answer.answer.value)}</span>);
                                } else if (questions.length === 2) {
                                    answer.push(
                                        ...questions[1].answer.answer.value.map((a, i) => (
                                            <div key={questions[0].id + i}>
                                                {a.country ? `${a.country}: ` : null}
                                                {a.start_of_living_abroad && a.end_of_living_abroad
                                                    ? `(${a.start_of_living_abroad.replaceAll("-", "/")} - ${a.end_of_living_abroad.replaceAll("-", "/")})`
                                                    : null}
                                            </div>
                                        ))
                                    );
                                } else {
                                    answer.push(<span key={questions[0].id}>TBD</span>);
                                }
                            } else if (group.label === "Travel History Outside US" && questions[0].answer) {
                                if (questions[0].answer.answer.value === "no") {
                                    answer.push(<span key={questions[0].id}>{handleStringAnswer(questions[0].answer.answer.value)}</span>);
                                } else if (questions.length === 2) {
                                    answer.push(
                                        ...questions[1].answer.answer.value.map((a, i) =>
                                            a.start_of_travel && a.end_of_travel ? (
                                                <div key={questions[0].id + i}>
                                                    {a.country}: {a.start_of_travel.replaceAll("-", "/")} - {a.end_of_travel.replaceAll("-", "/")}
                                                </div>
                                            ) : a.country ? (
                                                <div key={questions[0].id + i}>{a.country}</div>
                                            ) : null
                                        )
                                    );
                                } else {
                                    answer.push(<span key={questions[0].id}>TBD</span>);
                                }
                            } else if (group.label === "Race" && questions[0].answer) {
                                let race = "";

                                questions[0].answer.answer.value.forEach((val, i) => {
                                    if (i === questions[0].answer.answer.value.length - 1) {
                                        race += RaceMap[val];
                                    } else {
                                        race += `${RaceMap[val]}, `;
                                    }
                                });

                                answer.push(<div key={"raceID"}>{race}</div>);
                            } else if (group.label === "Ethnicities" && questions[0].answer) {
                                let mother = "Mother's side: ";
                                let father = "Father's side: ";

                                questions.forEach((q) => {
                                    if (q.answer && q.answer.answer && q.answer.answer.value) {
                                        if (q.slug === "mothers_ethnicities") {
                                            q.answer.answer.value.forEach((val, i) => {
                                                if (i === q.answer.answer.value.length - 1) {
                                                    mother += EthnicitiesMap[val.ethnicity];
                                                } else {
                                                    mother += `${EthnicitiesMap[val.ethnicity]}, `;
                                                }
                                            });
                                        } else {
                                            q.answer.answer.value.forEach((val, i) => {
                                                if (i === q.answer.answer.value.length - 1) {
                                                    father += EthnicitiesMap[val.ethnicity];
                                                } else {
                                                    father += `${EthnicitiesMap[val.ethnicity]}, `;
                                                }
                                            });
                                        }
                                    }
                                });

                                answer.push(<div key={"mother"}>{mother}</div>);
                                answer.push(<div key={"father"}>{father}</div>);
                            } else if (group.label === "Height" && questions[0].answer && questions[1].answer) {
                                answer.push(
                                    <span key={questions[0].id}>
                                        {questions[0].answer.answer.value}'{questions[1].answer.answer.value}"
                                    </span>
                                );
                            } else if (group.label === "Weight" && questions[0].answer) {
                                answer.push(<span key={questions[0].id}>{questions[0].answer.answer.value} lbs</span>);
                            } else if (group.label === "BMI" && questions[0].answer) {
                                answer.push(<span key={questions[0].id}>{questions[0].answer.answer.value}</span>);
                            } else if (group.label === "Degrees/Major" && questions[0].answer) {
                                questions[0].answer.answer.value.forEach((obj) => {
                                    answer.push(
                                        obj.specialty_or_major ? (
                                            <div key={obj.specialty_or_major}>
                                                {obj.specialty_or_major}
                                                {obj.degree_type ? `, ${DegreeTypesMap[obj.degree_type]}` : null}
                                                {obj.school_name ? `, ${obj.school_name}` : null}
                                            </div>
                                        ) : null
                                    );
                                });
                            } else if (group.label === "Current Occupation" && (questions[0].answer || questions[1].answer)) {
                                answer.push(
                                    <span key={questions[0].id}>
                                        {questions[0].answer ? handleStringAnswer(questions[0].answer.answer.value) : null}
                                        {questions[0].answer && questions[1].answer
                                            ? `, ${handleStringAnswer(questions[1].answer.answer.value)}`
                                            : questions[1].answer
                                            ? handleStringAnswer(questions[1].answer.answer.value)
                                            : null}
                                    </span>
                                );
                            } else if (group.label === "Occupation History" && questions[0].answer) {
                                if (questions[0].answer.answer.value === "no") {
                                    answer.push(<span key={questions[0].id}>No occupation history</span>);
                                } else if (questions[1].answer && questions[1].answer.answer && questions[1].answer.answer.value) {
                                    questions[1].answer.answer.value.forEach((job, i) => {
                                        answer.push(
                                            <div key={job.job_title + i}>
                                                {job.job_field ? handleStringAnswer(job.job_field) : null}
                                                {job.job_field && job.job_title ? `, ` : null}
                                                {job.job_title ? `${job.job_title}` : null}
                                            </div>
                                        );
                                    });
                                }
                            } else if (group.label === "Volunteer Work" && questions[0].answer) {
                                if (questions[0].answer.answer.value === "no") {
                                    answer.push(<span key={questions[0].id}>No volunteer history</span>);
                                } else {
                                    questions[1].answer &&
                                        questions[1].answer.answer.value.forEach((vol, i) => {
                                            answer.push(vol.volunteer_work ? <div key={questions[0].id + i}>{vol.volunteer_work}</div> : null);
                                        });
                                }
                            } else if (group.label === "Musical Ability" && questions[0].answer) {
                                if (questions[0].answer.answer.value === "no") {
                                    answer.push(<span key={questions[0].id}>No musical ability</span>);
                                } else if (questions[1].answer) {
                                    answer.push(<span key={questions[1].id}>{questions[1].answer.answer.value}</span>);
                                }
                            } else if (group.label === "Languages" && questions[0].answer) {
                                if (questions[0].answer.answer.value === "no") {
                                    answer.push(<span key={questions[0].id}>No other languages</span>);
                                } else {
                                    questions[1].answer &&
                                        questions[1].answer.answer.value.forEach((lang, i) => {
                                            answer.push(
                                                <div key={questions[0].id + i}>
                                                    {lang.language ? handleStringAnswer(lang.language) : null}
                                                    {lang.language_proficiency ? `, ${handleStringAnswer(lang.language_proficiency)}` : null}
                                                </div>
                                            );
                                        });
                                }
                            } else if (group.label === "Aptitudes") {
                                questions.forEach((question) => {
                                    if (question.answer) {
                                        answer.push(
                                            <div key={question.id}>
                                                {handleStringAnswer(question.slug)}: {question.answer.answer.value}
                                            </div>
                                        );
                                    }
                                });
                            } else if (questions[0].answer && questions[0].value_type === "string") {
                                answer.push(<span key={questions[0].id}>{handleStringAnswer(questions[0].answer.answer.value)}</span>);
                            } else if (questions[0].answer && questions[0].value_type === "string_array") {
                                answer.push(<span key={questions[0].id}>{handleStringAnswer(questions[0].answer.answer.value.join(", "))}</span>);
                            }

                            return (
                                <tr className={`${r % 2 === 0 ? "bg-white" : "bg-white-100"}`} key={group.id}>
                                    <td key={`${group.id}-flags`} className="text-left py-2 border-b border-dashed border-gray-300 px-5">
                                        <div className="">{flags}</div>
                                    </td>
                                    <td key={`${group.id}-label`} className="text-left py-2 border-b border-dashed border-gray-300 font-normal">
                                        {group.label}
                                    </td>
                                    <td key={`${group.id}-answer`} className="text-left py-2 border-b border-dashed border-gray-300">
                                        {answer}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            );
        } else if (form.flow === "split-application-family-medical-history") {
            let r = -1;

            return (
                <table className="table-fixed">
                    <thead>
                        <tr>
                            <th className="w-[3%] text-left py-2 border-b border-dashed border-gray-300 px-5">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-flag" viewBox="0 0 16 16">
                                    <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12 12 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A20 20 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a20 20 0 0 0 1.349-.476l.019-.007.004-.002h.001M14 1.221c-.22.078-.48.167-.766.255-.81.252-1.872.523-2.734.523-.886 0-1.592-.286-2.203-.534l-.008-.003C7.662 1.21 7.139 1 6.5 1c-.669 0-1.606.229-2.415.478A21 21 0 0 0 3 1.845v6.433c.22-.078.48-.167.766-.255C4.576 7.77 5.638 7.5 6.5 7.5c.847 0 1.548.28 2.158.525l.028.01C9.32 8.29 9.86 8.5 10.5 8.5c.668 0 1.606-.229 2.415-.478A21 21 0 0 0 14 7.655V1.222z" />
                                </svg>
                            </th>
                            <th className="w-1/4 text-left py-2 border-b border-dashed border-gray-300">Person</th>
                            <th className="w-1/4 text-left py-2 border-b border-dashed border-gray-300">Condition/Type</th>
                            <th className="w-1/4 text-left py-2 border-b border-dashed border-gray-300">Age diagnosed</th>
                            <th className="w-1/4 text-left py-2 border-b border-dashed border-gray-300">Treatment/Addtional Info</th>
                        </tr>
                    </thead>
                    <tbody className="">
                        {form.question_groups.map((group) => {
                            if (!group.open) {
                                return null;
                            }

                            const { questions } = group;

                            return questions.map((question) => {
                                if (!question.answer || question.question_priority === "primary") {
                                    return null;
                                }

                                const flag = (
                                    <svg
                                        key={question.answer.id}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill={`${question.answer.flag.color === "yellow" ? "gold" : question.answer.flag.color}`}
                                        class="bi bi-flag-fill mr-2"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12 12 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A20 20 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a20 20 0 0 0 1.349-.476l.019-.007.004-.002h.001" />
                                    </svg>
                                );

                                if (question.slug === "anything_else") {
                                    return (
                                        <tr className={`${r % 2 === 0 ? "bg-white" : "bg-white-100"}`} key={`${question.answer.id}`}>
                                            <td className="text-left py-2 border-b border-dashed border-gray-300 px-5">{flag}</td>
                                            <td className="text-left py-2 border-b border-dashed border-gray-300 font-normal">-</td>
                                            <td className="text-left py-2 px-4 border-b border-dashed border-gray-300">{question.label}</td>
                                            <td className="text-left py-2 border-b border-dashed border-gray-300">-</td>
                                            <td className="text-left py-2 border-b border-dashed border-gray-300 whitespace-pre-line">{handleStringAnswer(question.answer.answer.value)}</td>
                                        </tr>
                                    );
                                }

                                const rows = question.answer.answer.value.map((record, i) => {
                                    let extras = "";

                                    if (question.slug === "other_birth_defects" && record.birth_defect) {
                                        extras += `Birth defect: ${record.birth_defect}\n\n`;
                                    } else if (question.slug === "thyroid_cancer" && record.cancer_type) {
                                        extras += `Cancer type: ${record.cancer_type}\n\n`;
                                    } else if (question.slug === "other_cancer" && record.cancer) {
                                        extras += `Cancer type: ${record.cancer}\n\n`;
                                    } else if (question.slug === "other_hl_prior_50" && record.reason) {
                                        extras += `Reason: ${record.reason}\n\n`;
                                    } else if (question.slug === "other_hearing_disorder" && record.disorder) {
                                        extras += `Disorder: ${record.disorder}\n\n`;
                                    } else if (question.slug === "hepatitis" && record.hepatitis_type) {
                                        extras += `Hepatitis type: ${record.hepatitis_type}\n\n`;
                                    } else if (question.slug === "hepatitis" && record.hepatitit_details) {
                                        extras += `Details: ${record.hepatitit_details}\n\n`;
                                    } else if (question.slug === "stomach_ulcer" && record.pylori) {
                                        extras += `Due to pylori: ${handleStringAnswer(record.pylori)}\n\n`;
                                    } else if (question.slug === "other_digest" && record.disorder) {
                                        extras += `Disorder: ${record.disorder}\n\n`;
                                    } else if (question.slug === "other_genetic" && record.disorder) {
                                        extras += `Disorder: ${record.disorder}\n\n`;
                                    } else if (question.slug === "endometriosis" && record.severity) {
                                        extras += `Severity: ${record.severity}\n\n`;
                                    } else if (question.slug === "hysterectomy" && record.reason) {
                                        extras += `Reason: ${record.reason}\n\n`;
                                    } else if (question.slug === "infertility" && record.reason) {
                                        extras += `Reason: ${record.reason}\n\n`;
                                    } else if (question.slug === "miscarriages" && record.miscarriage_number) {
                                        extras += `# of miscarriages: ${record.miscarriage_number}\n\n`;
                                    } else if (question.slug === "stillborn" && record.reason) {
                                        extras += `Reason: ${record.reason}\n\n`;
                                    } else if (question.slug === "other_repro" && record.issue) {
                                        extras += `Issue: ${record.issue}\n\n`;
                                    } else if (question.slug === "arry_card_cond" && record.issue) {
                                        extras += `Issue: ${record.issue}\n\n`;
                                    } else if (question.slug === "hemophilia" && record.type) {
                                        extras += `Type: ${record.type}\n\n`;
                                    } else if (question.slug === "other_anem" && record.anemia) {
                                        extras += `Type: ${record.anemia}\n\n`;
                                    } else if (question.slug === "other_heart_blood_disorder" && record.disorder) {
                                        extras += `Disorder: ${record.disorder}\n\n`;
                                    } else if (question.slug === "eating_disorder" && record.disorder) {
                                        extras += `Disorder: ${record.disorder}\n\n`;
                                    } else if (question.slug === "learning_disability_delay" && record.disability) {
                                        extras += `Disability: ${record.disability}\n\n`;
                                    } else if (question.slug === "other_mh" && record.disorder) {
                                        extras += `Disorder: ${record.disorder}\n\n`;
                                    } else if (question.slug === "autoimmune_thyroid_disorder" && record.disorder) {
                                        extras += `Disorder: ${record.disorder}\n\n`;
                                    } else if (question.slug === "other_meta_endo" && record.diagnosis) {
                                        extras += `Disorder: ${record.diagnosis}\n\n`;
                                    } else if (question.slug === "other_muscle" && record.disorder) {
                                        extras += `Disorder: ${record.disorder}\n\n`;
                                    } else if (question.slug === "brain_tumor_abnormality" && record.type) {
                                        extras += `Type: ${record.type}\n\n`;
                                    } else if (question.slug === "cerebral_palsy" && record.peri_a_bi_premature) {
                                        extras += `Cause: ${record.peri_a_bi_premature}\n\n`;
                                    } else if (question.slug === "migraines" && record.aura) {
                                        extras += `Aura: ${record.aura}\n\n`;
                                    } else if (question.slug === "migraines" && record.freq_sev) {
                                        extras += `Frequency/Severity: ${record.freq_sev}\n\n`;
                                    } else if (question.slug === "other_neurological_disorder" && record.disorder) {
                                        extras += `Disorder: ${record.disorder}\n\n`;
                                    } else if (question.slug === "interstitial_lung_disease" && record.disease) {
                                        extras += `Disease: ${record.disease}\n\n`;
                                    } else if (question.slug === "other_respir" && record.disorder) {
                                        extras += `Disorder: ${record.disorder}\n\n`;
                                    } else if (question.slug === "psoriasis" && record.severity) {
                                        extras += `Severity: ${record.severity}\n\n`;
                                    } else if (question.slug === "vitiligo" && record.location) {
                                        extras += `Location: ${record.location}\n\n`;
                                    } else if (question.slug === "other_skin" && record.disorder) {
                                        extras += `Disorder: ${record.disorder}\n\n`;
                                    } else if (question.slug === "autoimmune_disease" && record.disease) {
                                        extras += `Disease: ${record.disease}\n\n`;
                                    } else if (question.slug === "immune_problem" && record.issue) {
                                        extras += `Issue: ${record.issue}\n\n`;
                                    } else if (question.slug === "other_kidney_defect_disorder" && record.disorder) {
                                        extras += `Disorder: ${record.disorder}\n\n`;
                                    } else if (question.slug === "other_visual_disorder" && record.disorder) {
                                        extras += `Disorder: ${record.disorder}\n\n`;
                                    } else if (question.slug === "other_health" && record.problem) {
                                        extras += `Problem: ${record.problem}\n\n`;
                                    }

                                    r++;

                                    if (question.slug === "death_prior_50") {
                                        return (
                                            <tr className={`${r % 2 === 0 ? "bg-white" : "bg-white-100"}`} key={`${question.answer.id}-${i}`}>
                                                <td className="text-left py-2 border-b border-dashed border-gray-300 px-5">{flag ? flag : null}</td>
                                                <td className="text-left py-2 border-b border-dashed border-gray-300 font-normal">{record.person ? handleStringAnswer(record.person) : null}</td>
                                                <td className="text-left py-2 px-4 border-b border-dashed border-gray-300">{question.label ? question.label : null}</td>
                                                <td className="text-left py-2 border-b border-dashed border-gray-300">{record.age_of_death ? record.age_of_death : null}</td>
                                                <td className="text-left py-2 border-b border-dashed border-gray-300 whitespace-pre-line">
                                                    {record.cause_of_death ? handleStringAnswer(record.cause_of_death) : null}
                                                </td>
                                            </tr>
                                        );
                                    }

                                    return (
                                        <tr className={`${r % 2 === 0 ? "bg-white" : "bg-white-100"}`} key={`${question.answer.id}-${i}`}>
                                            <td className="text-left py-2 border-b border-dashed border-gray-300 px-5">{flag ? flag : null}</td>
                                            <td className="text-left py-2 border-b border-dashed border-gray-300 font-normal">{record.person ? handleStringAnswer(record.person) : null}</td>
                                            <td className="text-left py-2 px-4 border-b border-dashed border-gray-300">{question.label ? question.label : null}</td>
                                            <td className="text-left py-2 border-b border-dashed border-gray-300">{record.diagnosis_age ? record.diagnosis_age : null}</td>
                                            <td className="text-left py-2 border-b border-dashed border-gray-300 whitespace-pre-line">
                                                {extras}
                                                {record.treatments ? handleStringAnswer(record.treatments) : null}
                                            </td>
                                        </tr>
                                    );
                                });

                                return rows;
                            });
                        })}
                    </tbody>
                </table>
            );
        } else if (form.flow === "freeze-personality-motivations") {
            return (
                <table className="table-fixed">
                    <thead>
                        <tr>
                            <th className="w-[3%] text-left py-2 border-b border-dashed border-gray-300 px-5">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-flag" viewBox="0 0 16 16">
                                    <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12 12 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A20 20 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a20 20 0 0 0 1.349-.476l.019-.007.004-.002h.001M14 1.221c-.22.078-.48.167-.766.255-.81.252-1.872.523-2.734.523-.886 0-1.592-.286-2.203-.534l-.008-.003C7.662 1.21 7.139 1 6.5 1c-.669 0-1.606.229-2.415.478A21 21 0 0 0 3 1.845v6.433c.22-.078.48-.167.766-.255C4.576 7.77 5.638 7.5 6.5 7.5c.847 0 1.548.28 2.158.525l.028.01C9.32 8.29 9.86 8.5 10.5 8.5c.668 0 1.606-.229 2.415-.478A21 21 0 0 0 14 7.655V1.222z" />
                                </svg>
                            </th>
                            <th className="w-1/4 text-left py-2 border-b border-dashed border-gray-300">Question</th>
                            <th className="w-1/4 text-left py-2 border-b border-dashed border-gray-300">Answer</th>
                        </tr>
                    </thead>
                    <tbody className="">
                        {form.question_groups.map((group, r) => {
                            if (!group.open) {
                                return null;
                            }

                            const { questions } = group;

                            return questions.map((question) => {
                                if (!question.answer) {
                                    return null;
                                }

                                return (
                                    <tr className={`${r % 2 === 0 ? "bg-white" : "bg-white-100"}`} key={`${question.answer.id}`}>
                                        <td className="text-left py-2 border-b border-dashed border-gray-300 px-5"></td>
                                        <td className="text-left py-2 border-b border-dashed border-gray-300 font-normal">{question.label ? handleStringAnswer(question.label) : null}</td>
                                        <td className="text-left py-2 border-b border-dashed border-gray-300 whitespace-pre-line">
                                            {question.answer ? handleStringAnswer(question.answer.answer.value) : null}
                                        </td>
                                    </tr>
                                );
                            });
                        })}
                    </tbody>
                </table>
            );
        }

        return (
            <table className="table-fixed">
                <thead>
                    <tr>
                        <th className="w-10 text-left py-2 border-b border-dashed border-gray-300 px-5">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-flag" viewBox="0 0 16 16">
                                <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12 12 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A20 20 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a20 20 0 0 0 1.349-.476l.019-.007.004-.002h.001M14 1.221c-.22.078-.48.167-.766.255-.81.252-1.872.523-2.734.523-.886 0-1.592-.286-2.203-.534l-.008-.003C7.662 1.21 7.139 1 6.5 1c-.669 0-1.606.229-2.415.478A21 21 0 0 0 3 1.845v6.433c.22-.078.48-.167.766-.255C4.576 7.77 5.638 7.5 6.5 7.5c.847 0 1.548.28 2.158.525l.028.01C9.32 8.29 9.86 8.5 10.5 8.5c.668 0 1.606-.229 2.415-.478A21 21 0 0 0 14 7.655V1.222z" />
                            </svg>
                        </th>
                        <th className="w-1/3 text-left py-2 border-b border-dashed border-gray-300">Category</th>
                        <th className="w-1/3 text-left py-2 border-b border-dashed border-gray-300">Answer</th>
                        <th className="w-1/3 text-left py-2 border-b border-dashed border-gray-300">Additional details</th>
                    </tr>
                </thead>
                <tbody>
                    {form.question_groups.map((group, r) => {
                        if (!group.open) {
                            return null;
                        }

                        const { questions } = group;

                        let flags = getGroupFlags(questions);
                        let answer = [];
                        let details = [];

                        if (group.label === "Nicotine products" && questions[0].answer) {
                            answer.push(<span key={questions[0].id}>{handleStringAnswer(questions[0].answer.answer.value)}</span>);

                            if (questions[1].answer) {
                                details.push(<span key={questions[1].id}>{questions[1].answer.answer.value}</span>);
                            }
                        } else if (group.label === "Drugs" && questions[0].answer) {
                            answer.push(<span key={questions[0].id}>{handleStringAnswer(questions[0].answer.answer.value)}</span>);

                            if (questions[1].answer) {
                                details.push(<span key={questions[1].id}>{questions[1].answer.answer.value}</span>);
                            }
                        } else if (group.label === "Alcohol" && questions[0].answer) {
                            answer.push(
                                <span key={questions[0].id}>
                                    {questions[0].answer.answer.value === "0_drinks"
                                        ? questions[0].answer.answer.value.replace(/_/g, " ")
                                        : `${questions[0].answer.answer.value.replace(/_/g, "-")} drinks`}
                                </span>
                            );
                        } else if (group.label === "Legal problems" && questions[0].answer) {
                            answer.push(<span key={questions[0].id}>{handleStringAnswer(questions[0].answer.answer.value)}</span>);

                            questions[1].answer &&
                                questions[1].answer.answer.value.forEach((obj, i) => {
                                    details.push(
                                        <div key={questions[1].id + i}>
                                            {obj.date_charged ? `${obj.date_charged.replaceAll("-", "/")}: ` : null}
                                            {obj.legal_charge ? obj.legal_charge.replaceAll("-", "/") : null}
                                        </div>
                                    );
                                });
                        } else if (group.label === "Jail time" && questions[0].answer) {
                            answer.push(<span key={questions[0].id}>{handleStringAnswer(questions[0].answer.answer.value)}</span>);

                            questions[1].answer &&
                                questions[1].answer.answer.value.forEach((obj, i) => {
                                    details.push(
                                        <div key={questions[1].id + i}>
                                            {obj.start_date ? `${obj.start_date.replaceAll("-", "/")} - ` : null}
                                            {obj.end_date ? `${obj.end_date.replaceAll("-", "/")}: ` : null}
                                            {obj.reason_for_incarceration ? obj.reason_for_incarceration : null}
                                        </div>
                                    );
                                });
                        } else if (group.label === "Piercings, acupuncture, tattoos" && questions[0].answer) {
                            answer.push(<span key={questions[0].id}>{handleStringAnswer(questions[0].answer.answer.value)}</span>);

                            if (questions[1].answer) {
                                details.push(<span key={questions[1].id}>Sterile equipment: {questions[1].answer.answer.value}</span>);
                            }
                        } else if (group.label === "Contraception" && questions[0].answer) {
                            questions.forEach((question) => {
                                if (question.slug === "contraception") {
                                    answer.push(
                                        <span key={question.id}>
                                            {questions[0].answer.answer.value
                                                .map((con, i) => {
                                                    if (i === questions[0].answer.answer.value.length - 1) {
                                                        return handleStringAnswer(con);
                                                    }

                                                    return `${handleStringAnswer(con)}, `;
                                                })
                                                .join(" ")}
                                        </span>
                                    );
                                } else if (question.slug === "other_contraception_method" && question.answer) {
                                    details.push(<div key={question.id}>Other method: {question.answer.answer.value}</div>);
                                } else if (question.slug === "birth_control_pills_brand" && question.answer) {
                                    details.push(<div key={question.id}>BC brand: {question.answer.answer.value}</div>);
                                } else if (question.slug === "depoprovera_last_shot" && question.answer) {
                                    details.push(<div key={question.id}>Depo last shot: {question.answer.answer.value.replaceAll("-", "/")}</div>);
                                } else if (question.slug === "depoprovera_next_shot" && question.answer) {
                                    details.push(<div key={question.id}>Depo next shot: {question.answer.answer.value.replaceAll("-", "/")}</div>);
                                }
                            });
                        } else if (group.label === "Menstruation regular" && questions[0].answer) {
                            answer.push(<span key={questions[0].id}>{handleStringAnswer(questions[0].answer.answer.value)}</span>);

                            if (questions[1].answer) {
                                details.push(<span key={questions[1].id}>{questions[1].answer.answer.value}</span>);
                            }
                        } else if (group.label === "Able to use BC before cycle" && questions[0].answer) {
                            answer.push(<span key={questions[0].id}>{handleStringAnswer(questions[0].answer.answer.value)}</span>);

                            if (questions[1].answer) {
                                details.push(<span key={questions[1].id}>{questions[1].answer.answer.value}</span>);
                            }
                        } else if (group.label === "Past egg retrieval cycles" && questions[0].answer) {
                            const value = questions[0].answer.answer.value;
                            answer.push(<span key={questions[0].id}>{handleStringAnswer(value)}</span>);

                            if (value === "yes") {
                                questions[1].answer &&
                                    questions[1].answer.answer.value.forEach((item) => {
                                        details.push(
                                            <div className="my-3">
                                                <div>Reason: {item.freeze_cycle_reason ? handleStringAnswer(item.freeze_cycle_reason) : null}</div>
                                                <div>Program: {item.egg_retrieval_history_program ? item.egg_retrieval_history_program : null}</div>
                                                <div>Clinic: {item.egg_retrieval_history_clinic ? item.egg_retrieval_history_clinic : null}</div>
                                                <div>When: {item.egg_retrieval_history_date ? item.egg_retrieval_history_date.replaceAll("-", "/") : null}</div>
                                                <div>Mature egg count: {item.egg_retrieval_history_count ? item.egg_retrieval_history_count : null}</div>
                                            </div>
                                        );
                                    });
                            }
                        } else if (group.label === "Currently enrolled in program" && questions[0].answer) {
                            const value = questions[0].answer.answer.value;
                            answer.push(<span key={questions[0].id}>{handleStringAnswer(value)}</span>);

                            if (value === "yes") {
                                details.push(questions[1].answer ? <div>{handleStringAnswer(questions[1].answer.answer.value)}</div> : null);
                            }
                        } else if (group.label === "Past AMH tested" && questions[0].answer) {
                            const value = questions[0].answer.answer.value;
                            answer.push(<span key={questions[0].id}>{handleStringAnswer(value)}</span>);

                            if (value === "yes") {
                                details.push(
                                    <div>
                                        <div>Date tested: {questions[1].answer ? handleStringAnswer(questions[1].answer.answer.value.replaceAll("-", "/")) : null}</div>
                                        <div>Result: {questions[2].answer ? handleStringAnswer(questions[2].answer.answer.value) : null}</div>
                                        <div>On BC: {questions[3].answer ? handleStringAnswer(questions[3].answer.answer.value) : null}</div>
                                        {questions[3].answer && questions[3].answer.answer.value === "yes" ? (
                                            <div>BC type: {questions[4].answer ? handleStringAnswer(questions[4].answer.answer.value) : null}</div>
                                        ) : null}
                                    </div>
                                );
                            }
                        } else if (group.label === "Past fertility testing" && questions[0].answer) {
                            answer.push(<span key={questions[0].id}>{handleStringAnswer(questions[0].answer.answer.value)}</span>);

                            if (questions[1].answer) {
                                details.push(<span key={questions[1].id}>{questions[1].answer.answer.value}</span>);
                            }
                        } else if (group.label === "STI or pelvic infection" && questions[0].answer) {
                            const value = questions[0].answer.answer.value;
                            answer.push(<span key={questions[0].id}>{handleStringAnswer(value)}</span>);

                            if (value === "yes") {
                                questions[1].answer &&
                                    questions[1].answer.answer.value.forEach((item) => {
                                        details.push(
                                            <div className="my-3">
                                                {item.sti ? <div>STI: {item.sti}</div> : null}
                                                {item.sti_treatment_date ? <div>Treatment date: {item.sti_treatment_date.replaceAll("-", "/")}</div> : null}
                                            </div>
                                        );
                                    });
                            }
                        } else if (group.label === "Pregnant" && questions[0].answer) {
                            answer.push(<span key={questions[0].id}>{handleStringAnswer(questions[0].answer.answer.value)}</span>);

                            if (questions[1].answer) {
                                details.push(<span key={questions[1].id}>Due date: {questions[1].answer.answer.value.replaceAll("-", "/")}</span>);
                            }
                        } else if (group.label === "Breastfeeding" && questions[0].answer) {
                            answer.push(<span key={questions[0].id}>{handleStringAnswer(questions[0].answer.answer.value)}</span>);

                            if (questions[1].answer) {
                                details.push(<span key={questions[1].id}>Planned end date: {questions[1].answer.answer.value.replaceAll("-", "/")}</span>);
                            }
                        } else if (group.label === "Past pregnancy" && questions[0].answer) {
                            const value = questions[0].answer.answer.value;
                            answer.push(<span key={questions[0].id}>{handleStringAnswer(value)}</span>);

                            if (value === "yes") {
                                details.push(questions[1].answer ? <div key={questions[1].id}>Total pregnancies: {questions[1].answer.answer.value}</div> : null);
                                details.push(questions[2].answer ? <div key={questions[2].id}>Miscarriages: {questions[2].answer.answer.value}</div> : null);
                                details.push(questions[3].answer ? <div key={questions[3].id}>Abortions: {questions[3].answer.answer.value}</div> : null);
                                details.push(questions[4].answer ? <div key={questions[4].id}>Stillbirths: {questions[4].answer.answer.value}</div> : null);
                                details.push(questions[5].answer ? <div key={questions[5].id}>Live births: {questions[5].answer.answer.value}</div> : null);

                                if (questions[6].answer) {
                                    questions[6].answer.answer.value.forEach((item) => {
                                        details.push(
                                            <div className="my-3">
                                                {item.sex_at_birth ? <div>Sex at birth: {item.sex_at_birth}</div> : null}
                                                {item.delivery_date ? <div>Delivery date: {item.delivery_date.replaceAll("-", "/")}</div> : null}
                                                {item.complications ? <div>Complications: {item.complications ? item.complications : "None"}</div> : null}
                                                {item.length_at_birth_cm ? <div>Length at birth (cm): {item.length_at_birth_cm}</div> : null}
                                                {item.weight_at_birth_lbs ? <div>Weight at birth (lbs): {item.weight_at_birth_lbs}</div> : null}
                                                {item.eye_color_at_birth ? <div>Eye color at birth: {item.eye_color_at_birth}</div> : null}
                                                {item.hair_color_at_birth ? <div>Hair color at birth: {item.hair_color_at_birth}</div> : null}
                                            </div>
                                        );
                                    });
                                }
                            }
                        } else if (group.label === "Vision" && questions[0].answer) {
                            answer.push(<span key={questions[0].id}>{handleStringAnswer(questions[0].answer.answer.value)}</span>);

                            if (questions[1].answer) {
                                details.push(<div key={questions[1].id}>Near or far sighted: {handleStringAnswer(questions[1].answer.answer.value)}</div>);
                            }
                            if (questions[2].answer) {
                                details.push(<div key={questions[2].id}>Corrective lenses: {handleStringAnswer(questions[2].answer.answer.value)}</div>);
                            }
                        } else if (group.label === "Dental" && questions[0].answer) {
                            answer.push(<span key={questions[0].id}>{handleStringAnswer(questions[0].answer.answer.value)}</span>);

                            if (questions[1].answer) {
                                details.push(<div key={questions[1].id}>{questions[1].answer.answer.value}</div>);
                            }
                        } else if (group.label === "Allergies" && questions[0].answer) {
                            const value = questions[0].answer.answer.value;
                            answer.push(<span key={questions[0].id}>{handleStringAnswer(value)}</span>);

                            if (questions[1].answer) {
                                questions[1].answer.answer.value.forEach((item) => {
                                    details.push(
                                        <div className="my-3">
                                            {item.allergy_substance ? <div>Allergy: {item.allergy_substance}</div> : null}
                                            {item.allergy_symptoms ? <div>Symptoms: {item.allergy_symptoms}</div> : null}
                                            {item.allergy_reaction ? <div>First reaction age: {item.allergy_reaction}</div> : null}
                                        </div>
                                    );
                                });
                            }
                        } else if (group.label === "Outgrown allergies" && questions[0].answer) {
                            answer.push(<span key={questions[0].id}>{handleStringAnswer(questions[0].answer.answer.value)}</span>);

                            if (questions[1].answer) {
                                details.push(<div key={questions[1].id}>{questions[1].answer.answer.value}</div>);
                            }
                        } else if (group.label === "Prescription meds (12mo)" && questions[0].answer) {
                            const value = questions[0].answer.answer.value;
                            answer.push(<span key={questions[0].id}>{handleStringAnswer(value)}</span>);

                            if (questions[1].answer) {
                                questions[1].answer.answer.value.forEach((item) => {
                                    details.push(
                                        <div className="my-3">
                                            {item.medication_name ? <div>Medication: {item.medication_name}</div> : null}
                                            {item.medication_reason ? <div>Reason: {item.medication_reason}</div> : null}
                                            <div>Date last taken: {item.medication_date_last_taken ? item.medication_date_last_taken.replaceAll("-", "/") : "NA"}</div>
                                            {item.medication_length_of_time ? <div>Time taken: {item.medication_length_of_time}</div> : null}
                                            {item.medication_frequency ? <div>Frequency: {handleStringAnswer(item.medication_frequency)}</div> : null}
                                            <div>Willing to stop: {item.medication_willing_stop ? handleStringAnswer(item.medication_willing_stop) : "NA"}</div>
                                        </div>
                                    );
                                });
                            }
                        } else if (group.label === "OTC meds (12mo)" && questions[0].answer) {
                            const value = questions[0].answer.answer.value;
                            answer.push(<span key={questions[0].id}>{handleStringAnswer(value)}</span>);

                            if (questions[1].answer) {
                                questions[1].answer.answer.value.forEach((item) => {
                                    details.push(
                                        <div className="my-3">
                                            {item.otc_name ? <div>Medication: {item.otc_name}</div> : null}
                                            {item.otc_reason ? <div>Reason: {item.otc_reason}</div> : null}
                                            {item.otc_date_last_taken ? <div>Date last taken: {item.otc_date_last_taken.replaceAll("-", "/")}</div> : null}
                                        </div>
                                    );
                                });
                            }
                        } else if (group.label === "Vitamins/supplements (12mo)" && questions[0].answer) {
                            answer.push(<span key={questions[0].id}>{handleStringAnswer(questions[0].answer.answer.value)}</span>);

                            if (questions[1].answer) {
                                details.push(<div key={questions[1].id}>{questions[1].answer.answer.value}</div>);
                            }
                        } else if (group.label === "Diet" && questions[0].answer) {
                            answer.push(<span key={questions[0].id}>{handleStringAnswer(questions[0].answer.answer.value)}</span>);

                            if (questions[1].answer) {
                                details.push(<div key={questions[1].id}>{questions[1].answer.answer.value}</div>);
                            }
                        } else if (group.label === "Surgical history" && questions[0].answer) {
                            const value = questions[0].answer.answer.value;
                            answer.push(<span key={questions[0].id}>{handleStringAnswer(value)}</span>);

                            if (questions[1].answer) {
                                questions[1].answer.answer.value.forEach((item) => {
                                    details.push(
                                        <div className="my-3">
                                            {item.surgery_name ? <div>Surgery: {item.surgery_name}</div> : null}
                                            {item.surgery_reason ? <div>Reason: {item.surgery_reason}</div> : null}
                                            {item.surgery_date ? <div>Date: {item.surgery_date.replaceAll("-", "/")}</div> : null}
                                            {item.surgery_age ? <div>Age: {item.surgery_age}</div> : null}
                                        </div>
                                    );
                                });
                            }
                        } else if (group.label === "Anesthesia history" && questions[0].answer) {
                            answer.push(<span key={questions[0].id}>{handleStringAnswer(questions[0].answer.answer.value)}</span>);

                            if (questions[1].answer) {
                                details.push(<div key={questions[1].id}>{questions[1].answer.answer.value}</div>);
                            }
                        } else if (group.label === "Vaccines (12mo)" && questions[0].answer) {
                            answer.push(<span key={questions[0].id}>{handleStringAnswer(questions[0].answer.answer.value)}</span>);

                            if (questions[1].answer) {
                                details.push(<div key={questions[1].id}>{questions[1].answer.answer.value}</div>);
                            }
                            if (questions[2].answer) {
                                details.push(
                                    <div key={questions[2].id}>
                                        COVID-19 vaccine: {questions[2].answer.answer.value}
                                        {questions[2].answer.answer.value === "yes" && questions[3].answer ? ", " + questions[3].answer.answer.value : ""}
                                    </div>
                                );
                            }
                        } else if (group.label === "Hospitalizations, surgeries, or institutionalizations (2yr)" && questions[0].answer) {
                            const value = questions[0].answer.answer.value;
                            answer.push(<span key={questions[0].id}>{handleStringAnswer(value)}</span>);

                            if (questions[1].answer) {
                                questions[1].answer.answer.value.forEach((item) => {
                                    details.push(
                                        <div className="my-3">
                                            {item.serious_diagnosis ? <div>Diagnosis: {item.serious_diagnosis}</div> : null}
                                            {item.age_serious_diagnosis ? <div>Age: {item.age_serious_diagnosis}</div> : null}
                                            {item.treatment_serious_diagnosis ? <div>Treatments: {item.treatment_serious_diagnosis}</div> : null}
                                        </div>
                                    );
                                });
                            }
                        } else if (group.label === "Refused blood donor" && questions[0].answer) {
                            answer.push(<span key={questions[0].id}>{handleStringAnswer(questions[0].answer.answer.value)}</span>);

                            if (questions[1].answer) {
                                details.push(<div key={questions[1].id}>{questions[1].answer.answer.value}</div>);
                            }
                        } else if (group.label === "Blood transfusions" && questions[0].answer) {
                            answer.push(<span key={questions[0].id}>{handleStringAnswer(questions[0].answer.answer.value)}</span>);

                            if (questions[1].answer) {
                                details.push(<div key={questions[1].id}>{questions[1].answer.answer.value}</div>);
                            }
                        } else if (group.label === "Eating disorders" && questions[0].answer) {
                            const value = questions[0].answer.answer.value;
                            answer.push(<span key={questions[0].id}>{handleStringAnswer(value)}</span>);

                            if (questions[1].answer) {
                                questions[1].answer.answer.value.forEach((item) => {
                                    details.push(
                                        <div className="my-3">
                                            {item.ed_diagnosis ? <div>Diagnosis: {item.ed_diagnosis}</div> : null}
                                            {item.ed_age_diagnosed ? <div>Age: {item.ed_age_diagnosed}</div> : null}
                                            {item.ed_treatments ? <div>Treatments: {item.ed_treatments}</div> : null}
                                            {item.ed_treatment_length ? <div>Treatment lenght: {item.ed_treatment_length}</div> : null}
                                            {item.ed_remission ? <div>Remission: {item.ed_remission}</div> : null}
                                            {item.ed_triggers ? <div>Triggers: {item.ed_triggers}</div> : null}
                                        </div>
                                    );
                                });
                            }
                        } else if (group.label === "Toxic substance exposure" && questions[0].answer) {
                            answer.push(<span key={questions[0].id}>{handleStringAnswer(questions[0].answer.answer.value)}</span>);

                            if (questions[1].answer) {
                                details.push(<div key={questions[1].id}>{questions[1].answer.answer.value}</div>);
                            }
                        } else if (group.label === "Occupational exposure" && questions[0].answer) {
                            answer.push(<span key={questions[0].id}>{handleStringAnswer(questions[0].answer.answer.value)}</span>);

                            if (questions[1].answer) {
                                details.push(<div key={questions[1].id}>{questions[1].answer.answer.value}</div>);
                            }
                        } else if (group.label === "Religion" && questions[0].answer) {
                            answer.push(<span key={questions[0].id}>{handleStringAnswer(questions[0].answer.answer.value.join(", "))}</span>);

                            if (questions[1].answer) {
                                details.push(<div key={questions[1].id}>{questions[1].answer.answer.value}</div>);
                            }
                        } else if (group.label === "Mother" && questions[0].answer) {
                            details.push(
                                <div>
                                    <div>Eye color: {handleStringAnswer(questions[0]?.answer?.answer?.value)}</div>
                                    <div>Hair color: {handleStringAnswer(questions[1]?.answer?.answer?.value)}</div>
                                    <div>Complexion: {handleStringAnswer(questions[2]?.answer?.answer?.value)}</div>
                                    <div>Frame: {handleStringAnswer(questions[3]?.answer?.answer?.value)}</div>
                                    {questions[4].answer && questions[5].answer && questions[4].answer.answer && questions[5].answer.answer ? (
                                        <div>
                                            Height: {questions[4].answer.answer.value}'{questions[5].answer.answer.value}"
                                        </div>
                                    ) : null}
                                    <div>Living: {handleStringAnswer(questions[6]?.answer?.answer?.value)}</div>
                                    {questions[6]?.answer?.answer?.value === "yes" ? (
                                        <div>Age: {questions[7]?.answer?.answer?.value}</div>
                                    ) : (
                                        <div>Age and cause of death: {handleStringAnswer(questions[8]?.answer?.answer?.value)}</div>
                                    )}
                                </div>
                            );
                        } else if (group.label === "Father" && questions[0].answer) {
                            details.push(
                                <div>
                                    <div>Eye color: {handleStringAnswer(questions[0]?.answer?.answer?.value)}</div>
                                    <div>Hair color: {handleStringAnswer(questions[1]?.answer?.answer?.value)}</div>
                                    <div>Complexion: {handleStringAnswer(questions[2]?.answer?.answer?.value)}</div>
                                    <div>Frame: {handleStringAnswer(questions[3]?.answer?.answer?.value)}</div>
                                    {questions[4].answer && questions[5].answer && questions[4].answer.answer && questions[5].answer.answer ? (
                                        <div>
                                            Height: {questions[4].answer.answer.value}'{questions[5].answer.answer.value}"
                                        </div>
                                    ) : null}
                                    <div>Living: {handleStringAnswer(questions[6]?.answer?.answer?.value)}</div>
                                    {questions[6]?.answer?.answer?.value === "yes" ? (
                                        <div>Age: {questions[7]?.answer?.answer?.value}</div>
                                    ) : (
                                        <div>Age and cause of death: {handleStringAnswer(questions[8]?.answer?.answer?.value)}</div>
                                    )}
                                </div>
                            );
                        } else if (group.label === "Siblings" && questions[0].answer) {
                            const value = questions[0].answer.answer.value;
                            answer.push(<span key={questions[0].id}>{handleStringAnswer(value)}</span>);

                            if (questions[1].answer) {
                                questions[1].answer.answer.value.forEach((item) => {
                                    details.push(
                                        <div className="my-3">
                                            {item.sex_at_birth ? <div>Sex: {handleStringAnswer(item.sex_at_birth)}</div> : null}
                                            {item.half_or_full_sibling ? <div>Half/full: {handleStringAnswer(item.half_or_full_sibling)}</div> : null}
                                            {item.eye_color ? <div>Eye color: {handleStringAnswer(item.eye_color)}</div> : null}
                                            {item.hair_color ? <div>Hair color: {handleStringAnswer(item.hair_color)}</div> : null}
                                            {item.complexion ? <div>Complexion: {handleStringAnswer(item.complexion)}</div> : null}
                                            {item.body_frame ? <div>Frame: {handleStringAnswer(item.body_frame)}</div> : null}
                                            {item.height_feet && item.height_inches ? (
                                                <div>
                                                    Height: {item.height_feet}'{item.height_inches}"
                                                </div>
                                            ) : null}
                                            {item.sibling_living ? <div>Living: {handleStringAnswer(item.sibling_living)}</div> : null}
                                            {item.sibling_living === "yes" ? item.sibling_age ? <div>Age: {item.sibling_age}</div> : <div>Age: Unknown</div> : null}

                                            {item.sibling_living === "no" ? (
                                                item.sibling_death_age ? (
                                                    <div>Age and cause of death: {handleStringAnswer(item.sibling_death_age)}</div>
                                                ) : (
                                                    <div>Age and cause of death: Unknown</div>
                                                )
                                            ) : null}
                                        </div>
                                    );
                                });
                            }
                        } else if (group.label === "Grandparents" && questions[0].answer) {
                            details.push(
                                <div>
                                    <div className="mb-3">
                                        {questions[0] && questions[0].answer && questions[0].answer.answer && questions[0].answer.answer.value ? (
                                            <div className="my-3">
                                                <div>Maternal grandmother</div>
                                                <div>Living: {handleStringAnswer(questions[0].answer.answer.value)}</div>
                                                {questions[0].answer.answer.value === "yes" ? (
                                                    <div>Age: {questions[1].answer.answer.value}</div>
                                                ) : questions[0].answer.answer.value === "no" ? (
                                                    <div>Age and cause of death: {handleStringAnswer(questions[2].answer.answer.value)}</div>
                                                ) : null}
                                            </div>
                                        ) : null}

                                        {questions[3] && questions[3].answer && questions[3].answer.answer && questions[3].answer.answer.value ? (
                                            <div className="my-3">
                                                <div>Maternal grandfather</div>
                                                <div>Living: {handleStringAnswer(questions[3].answer.answer.value)}</div>
                                                {questions[3].answer.answer.value === "yes" ? (
                                                    <div>Age: {questions[4].answer.answer.value}</div>
                                                ) : questions[3].answer.answer.value === "no" ? (
                                                    <div>Age and cause of death: {handleStringAnswer(questions[5].answer.answer.value)}</div>
                                                ) : null}
                                            </div>
                                        ) : null}
                                    </div>

                                    <div className="mt-3">
                                        {questions[6] && questions[6].answer && questions[6].answer.answer && questions[6].answer.answer.value ? (
                                            <div className="my-3">
                                                <div>Paternal grandmother</div>
                                                <div>Living: {handleStringAnswer(questions[6].answer.answer.value)}</div>
                                                {questions[6].answer.answer.value === "yes" ? (
                                                    <div>Age: {questions[7].answer.answer.value}</div>
                                                ) : questions[6].answer.answer.value === "no" ? (
                                                    <div>Age and cause of death: {handleStringAnswer(questions[8].answer.answer.value)}</div>
                                                ) : null}
                                            </div>
                                        ) : null}

                                        {questions[9] && questions[9].answer && questions[9].answer.answer && questions[9].answer.answer.value ? (
                                            <div className="my-3">
                                                <div>Paternal grandfather</div>
                                                <div>Living: {handleStringAnswer(questions[9].answer.answer.value)}</div>
                                                {questions[9].answer.answer.value === "yes" ? (
                                                    <div>Age: {questions[10].answer.answer.value}</div>
                                                ) : questions[9].answer.answer.value === "no" ? (
                                                    <div>Age and cause of death: {handleStringAnswer(questions[11].answer.answer.value)}</div>
                                                ) : null}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            );
                        } else if (questions[0].answer) {
                            const question = questions[0];
                            const value = question.answer.answer.value;

                            if (question.value_type === "string") {
                                answer.push(<span key={questions[0].id}>{handleStringAnswer(value)}</span>);
                            } else if (question.value_type === "int" || question.value_type === "float") {
                                answer.push(<span key={questions[0].id}>{value}</span>);
                            }
                        }

                        return (
                            <tr className={`w-full ${r % 2 === 0 ? "bg-white" : "bg-white-100"}`} key={group.id}>
                                <td className="w-10 text-left py-2 border-b border-dashed border-gray-300 px-5">{flags ? flags : null}</td>
                                <td className="w-1/3 text-left py-2 border-b border-dashed border-gray-300 font-normal">{group.label ? group.label : null}</td>
                                <td className="w-1/3 text-left py-2 border-b border-dashed border-gray-300">{answer ? answer : null}</td>
                                <td className="w-1/3 text-left py-2 border-b border-dashed border-gray-300">{details ? details : null}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    };

    // answer.push(<span key={questions[0].id}>{handleStringAnswer(questions[0].answer.answer.value)}</span>)

    // if (questions[1].answer) {
    //     details.push(<span key={questions[1].id}>{questions[1].answer.answer.value}</span>)
    // }

    return (
        <div>
            <div className="mx-30 mb-10 flex items-center">
                <span className="mr-5">
                    <input
                        id="search-input"
                        onChange={handleInputChange}
                        value={searchTerm}
                        className="form-control bg-gray-100 rounded-lg px-3 py-2"
                        type="search"
                        name="search"
                        placeholder="Search..."
                    />
                </span>
                <button
                    onClick={() => handleFlagFilterChange("red")}
                    className={`border ${flagFilters.includes("red") ? "border-red-500 border-2" : "border-gray-300"} rounded-lg px-3 py-2 mr-3`}
                    value="red"
                >
                    <div className="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-flag-fill mr-2" viewBox="0 0 16 16">
                            <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12 12 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A20 20 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a20 20 0 0 0 1.349-.476l.019-.007.004-.002h.001" />
                        </svg>
                        <div>High ({flagCounts.red})</div>
                    </div>
                </button>
                <button
                    onClick={() => handleFlagFilterChange("orange")}
                    className={`border ${flagFilters.includes("orange") ? "border-orange-500 border-2" : "border-gray-300"} rounded-lg px-3 py-2 mr-3`}
                    value="orange"
                >
                    <div className="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="orange" class="bi bi-flag-fill mr-2" viewBox="0 0 16 16">
                            <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12 12 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A20 20 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a20 20 0 0 0 1.349-.476l.019-.007.004-.002h.001" />
                        </svg>
                        <div>Medium ({flagCounts.orange})</div>
                    </div>
                </button>
                <button
                    onClick={() => handleFlagFilterChange("yellow")}
                    className={`border ${flagFilters.includes("yellow") ? "border-yellow-500 border-2" : "border-gray-300"} rounded-lg px-3 py-2 mr-3`}
                    value="yellow"
                >
                    <div className="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="gold" class="bi bi-flag-fill mr-2" viewBox="0 0 16 16">
                            <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12 12 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A20 20 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a20 20 0 0 0 1.349-.476l.019-.007.004-.002h.001" />
                        </svg>
                        <div>Low ({flagCounts.yellow})</div>
                    </div>
                </button>
                {openAll ? (
                    <div onClick={toggleOpenAll} className="inline-block ml-auto">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clipRule="evenodd" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                        </svg>
                    </div>
                ) : (
                    <div onClick={toggleOpenAll} className="inline-block ml-auto">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clipRule="evenodd" />
                        </svg>
                    </div>
                )}
            </div>

            <div id="profile-sections" className="whitespace-pre-wrap">
                {filteredApps.map((form) => {
                    let flagCount = {
                        yellow: 0,
                        orange: 0,
                        red: 0,
                    };

                    form.question_groups.forEach((group) => {
                        group.questions.forEach((question) => {
                            if (question.answer && question.answer.flag) {
                                if (flagCount.hasOwnProperty(question.answer.flag.color)) {
                                    flagCount[question.answer.flag.color]++;
                                }
                            }
                        });
                    });

                    return (
                        <div key={form.id}>
                            <div className="mx-30 mb-10">
                                <div className="col-span-4 col-start-1 mb-10">
                                    <div className="flex justify-between">
                                        <h2 onClick={() => handleOpenToggle(form.id)} className="inline-block">
                                            <span className="heading-underline-forest-50 mr-5">{form.name}</span>
                                        </h2>
                                        <span className="inline-block">
                                            {form.open ? (
                                                <button
                                                    key={`${form.id}`}
                                                    className="bg-white text-secondary border border-secondary rounded-full text-base font-normal py-1 px-4 flex items-center"
                                                    onClick={() => redirectToFormsort({ form, user, staff: loggedInUser, newTab: true })}
                                                >
                                                    <div className="inline-block mr-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                                                            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                                                        </svg>
                                                    </div>
                                                    Edit
                                                </button>
                                            ) : (
                                                <div className="flex">
                                                    <div className="mr-5">
                                                        {flagCount.red === 0 ? (
                                                            "-"
                                                        ) : (
                                                            <div className="flex items-center font-body text-base font-light">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-flag-fill mr-2" viewBox="0 0 16 16">
                                                                    <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12 12 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A20 20 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a20 20 0 0 0 1.349-.476l.019-.007.004-.002h.001" />
                                                                </svg>
                                                                <div>{flagCount.red}</div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="mr-5">
                                                        {flagCount.orange === 0 ? (
                                                            "-"
                                                        ) : (
                                                            <div className="flex items-center font-body text-base font-light">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="orange" class="bi bi-flag-fill mr-2" viewBox="0 0 16 16">
                                                                    <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12 12 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A20 20 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a20 20 0 0 0 1.349-.476l.019-.007.004-.002h.001" />
                                                                </svg>
                                                                <div>{flagCount.orange}</div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="mr-5">
                                                        {flagCount.yellow === 0 ? (
                                                            "-"
                                                        ) : (
                                                            <div className="flex items-center font-body text-base font-light">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="gold" class="bi bi-flag-fill mr-2" viewBox="0 0 16 16">
                                                                    <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12 12 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A20 20 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a20 20 0 0 0 1.349-.476l.019-.007.004-.002h.001" />
                                                                </svg>
                                                                <div>{flagCount.yellow}</div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <button
                                                        key={`${form.id}`}
                                                        className="bg-white text-secondary border border-secondary rounded-full text-base font-normal py-1 px-4"
                                                        onClick={() => redirectToFormsort({ form, user, staff: loggedInUser, newTab: true })}
                                                    >
                                                        <div className="inline-block mr-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                                                                <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                                                            </svg>
                                                        </div>
                                                        Edit
                                                    </button>
                                                </div>
                                            )}
                                        </span>
                                    </div>

                                    {form.open ? <div className="col-span-8 col-start-5 mt-3 relative">{determineTable(form)}</div> : null}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Profile;
