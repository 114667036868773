import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const SimpleModal = ({ inputs, leftHeaders = [], title, bodyText, close = null, closeText = "Close", save, saveText = "Save" }) => {
    return (
        <div
            className="modal"
            style={{
                position: "fixed",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 30,
            }}
        >
            <div
                className="modal-content w-full max-w-3xl"
                style={{
                    backgroundColor: "white",
                    padding: "40px",
                    borderRadius: "5px",
                    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.3)",
                }}
            >
                <h3 className="text-2xl font-semibold mb-4 flex justify-between items-center">
                    <span className="heading-underline-forest-50 ">{title}</span>
                    {leftHeaders.map((h) => h)}
                </h3>
                {bodyText ? <div className="whitespace-pre-line p-4">{bodyText}</div> : null}
                {inputs ? (
                    <div className="grid grid-cols-2 gap-x-6 gap-y-4 rounded-md p-4">
                        {inputs.map((i) => {
                            if (i.textArea) {
                                return (
                                    <div className="col-span-2">
                                        {i.title ? <label className="text-sm font-medium">{i.title}:</label> : null}
                                        <textarea
                                            className="w-full px-2 py-1"
                                            value={i.value}
                                            onChange={(e) => i.handleChange(e, i.setInputState)}
                                            rows={10}
                                            cols={50}
                                            placeholder={i.placeholder}
                                            autoFocus={true}
                                        />
                                    </div>
                                );
                            } else if (i.type === "datepicker") {
                                return (
                                    <div className="p-1 w-3/4">
                                        <label className="text-sm font-medium">{i.title}:</label>
                                        <DatePicker
                                            selected={i.value}
                                            className="border border-solid border-black rounded-lg p-2"
                                            onChange={(update) => {
                                                i.handleChange(update);
                                            }}
                                            isClearable
                                            placeholderText={i.placeholder}
                                        />
                                    </div>
                                );
                            } else if (i.type === "checkbox") {
                                return (
                                    <div className={`${i.fullWidth ? "col-span-2 md:col-span-1" : "flex"}`}>
                                        <input className="" onClick={() => i.setInputState(!i.value)} checked={i.value} type={"checkbox"} />
                                        <label className="text-sm font-medium ml-2">{i.title}</label>
                                    </div>
                                );
                            }

                            return (
                                <div className={`${i.fullWidth ? "col-span-2 md:col-span-1" : ""}`}>
                                    <label className="text-sm font-medium">{i.title}:</label>
                                    <input
                                        className="form-input mt-1 w-full border border-gray-300 rounded-lg px-2"
                                        onChange={(event) => i.handleChange(event, i.setInputState)}
                                        value={i.value}
                                        type={i.type ? i.type : "text"}
                                        step={i.step}
                                    />
                                </div>
                            );
                        })}
                    </div>
                ) : null}
                <div className="grid grid-cols-2 gap-x-6 gap-y-4 rounded-md p-4">
                    <div className="col-span-2 flex justify-end mt-4">
                        {close ? (
                            <button onClick={close} className="mr-2 px-4 py-2 rounded-md bg-gray-300 hover:bg-gray-400">
                                {closeText}
                            </button>
                        ) : null}
                        <button onClick={save} className="px-4 py-2 rounded-md bg-blue-500 hover:bg-blue-600 text-white">
                            {saveText}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
