import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import { SMDashboardContext } from "./Dashboard";
import { PronounOptions } from "../../util/helpers";

const SMSettings = () => {
    const [currentTab, setCurrentTab] = useState("personal-info");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [preferredName, setPreferredName] = useState("");
    const [pronouns, setPronouns] = useState("");
    const [pronounsOther, setPronounsOther] = useState("");
    const [phone, setPhone] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [city, setCity] = useState("");
    const [homeState, setHomeState] = useState("");
    const [zipcode, setZipcode] = useState("");
    const [country, setCountry] = useState("");
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const { loggedInUser, refreshUser, request } = useContext(AppContext);
    const { setCurrentPage } = useContext(SMDashboardContext);
    setCurrentPage("settings");

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top left corner
    }, []);

    useEffect(() => {
        setFirstName(loggedInUser.firstName);
        setLastName(loggedInUser.lastName);
        if (loggedInUser.preferredName) setPreferredName(loggedInUser.preferredName);
        if (loggedInUser.pronouns) setPronouns(loggedInUser.pronouns);
        if (loggedInUser.other_pronouns) setPronounsOther(loggedInUser.other_pronouns);
        if (loggedInUser.phoneNumber) setPhone(loggedInUser.phoneNumber);
        if (loggedInUser.address1) setAddress1(loggedInUser.address1);
        if (loggedInUser.address2) setAddress2(loggedInUser.address2);
        if (loggedInUser.city) setCity(loggedInUser.city);
        if (loggedInUser.state) setHomeState(loggedInUser.state);
        if (loggedInUser.zipcode) setZipcode(loggedInUser.zipcode);
        if (loggedInUser.country) setCountry(loggedInUser.country);
    }, [loggedInUser]);

    const handleInputChange = (event, setState) => {
        setState(event.target.value);
    };

    const saveContactEdits = async () => {
        try {
            await request({
                url: `/split-members/${loggedInUser.id}/contact-info`,
                method: "PUT",
                data: {
                    firstName,
                    lastName,
                    preferredName,
                    pronouns,
                    pronounsOther,
                    phone,
                    address1,
                    address2,
                    city,
                    state: homeState,
                    zipcode,
                    country,
                },
            });

            await refreshUser();
        } catch (err) {
            console.log(err);
        }
    };

    const handleString = (s, delimter = " ") => {
        if (typeof s !== "string") {
            return s;
        }

        if (s.includes("_")) {
            return s
                .split("_")
                .map((word, i) => {
                    if (i === 0) {
                        return word[0].toUpperCase() + word.substring(1);
                    }

                    return word;
                })
                .join(delimter);
        } else if (s.length > 1) {
            return s[0].toUpperCase() + s.substring(1);
        }

        return s;
    };

    return (
        <div>
            <div className="w-full sm:hidden flex justify-between items-center bg-[#D9CAF5] fixed top-20 z-10">
                <div className="text-secondary ml-4 font-semibold">Settings</div>
                <span onClick={() => setMobileMenuOpen(!mobileMenuOpen)} className={`${mobileMenuOpen ? "-rotate-180" : ""} w-8 h-8 ml-4 transition-transform flex-shrink-0`}>
                    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 13L16 19L22 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg>
                </span>
            </div>
            {mobileMenuOpen ? (
                <div className="w-full bg-[#AB99CB] fixed top-28 z-10">
                    <div className="ml-4 my-[5px] cursor-pointer text-secondary" onClick={() => setCurrentTab("personal-info")}>
                        Personal Info
                    </div>
                </div>
            ) : null}
            <div className="w-full min-h-screen mx-auto bg-gray-100 p-8 flex justify-center">
                <div className="w-full sm:w-3/4 lg:w-1/2 mt-10 sm:grid grid-cols-7">
                    <div className="hidden col-span-2 sm:flex flex-col">
                        <h1>
                            <span className="heading-underline">Settings</span>
                        </h1>
                        <h4 className="my-3">
                            <span onClick={() => setCurrentTab("personal-info")} className={`${currentTab === "personal-info" ? "font-semibold text-secondary" : ""} cursor-pointer`}>
                                Personal Info
                            </span>
                        </h4>
                    </div>
                    <div className="col-span-5 w-full sm:w-2/3">
                        {currentTab === "personal-info" ? (
                            <div className="flex flex-col">
                                <h4>Personal information</h4>
                                <div className="md:grid grid-cols-2 gap-2 my-2">
                                    <div>
                                        <div className="my-2">Legal first name</div>
                                        <input
                                            onChange={(event) => handleInputChange(event, setFirstName)}
                                            value={firstName}
                                            className="bg-white p-4 w-full py-2 rounded-lg mt-2 border border-black"
                                        />
                                    </div>
                                    <div>
                                        <div className="my-2">Legal last name</div>
                                        <input onChange={(e) => handleInputChange(e, setLastName)} className="bg-white p-4 w-full py-2 rounded-lg mt-2 border border-black" value={lastName} />
                                    </div>
                                    <div className="col-span-2">
                                        <div className="my-2">Preferred name</div>
                                        <input
                                            onChange={(e) => handleInputChange(e, setPreferredName)}
                                            className="bg-white p-4 w-full py-2 rounded-lg mt-2 border border-black"
                                            value={preferredName}
                                        />
                                    </div>
                                    <div className="col-span-2">
                                        <div className="my-2">Pronouns</div>
                                        <select
                                            className="bg-white p-4 w-full py-2 rounded-lg mt-2 border border-black"
                                            value={pronouns}
                                            onChange={(e) => {
                                                if (e.target.value === "other") {
                                                    setPronounsOther("");
                                                }
                                                setPronouns(e.target.value);
                                            }}
                                        >
                                            <option value="" disabled hidden>
                                                Pronouns...
                                            </option>
                                            {PronounOptions.map((option) => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </select>
                                        {pronouns === "other" ? (
                                            <input
                                                onChange={(e) => handleInputChange(e, setPronounsOther)}
                                                className="bg-white p-4 w-full py-2 rounded-lg mt-4 border border-black"
                                                value={handleString(pronounsOther)}
                                                placeholder="Other pronouns..."
                                            />
                                        ) : null}
                                    </div>
                                </div>
                                <div className="w-full border-b border-solid border-gray-300 my-4"></div>
                                <h4>Contact information</h4>
                                <div>
                                    <div className="my-2">Address line 1</div>
                                    <input onChange={(e) => handleInputChange(e, setAddress1)} className="bg-white p-4 w-full py-2 rounded-lg mt-2 border border-black" value={address1} />
                                </div>
                                <div>
                                    <div className="my-2">Address line 2</div>
                                    <input onChange={(e) => handleInputChange(e, setAddress2)} className="bg-white p-4 w-full py-2 rounded-lg mt-2 border border-black" value={address2} />
                                </div>
                                <div className="md:grid grid-cols-3 gap-2 my-2">
                                    <div>
                                        <div>Zip code</div>
                                        <input onChange={(e) => handleInputChange(e, setZipcode)} className="bg-white p-4 w-full py-2 rounded-lg mt-2 border border-black" value={zipcode} />
                                    </div>
                                    <div>
                                        <div>City</div>
                                        <input onChange={(e) => handleInputChange(e, setCity)} className="bg-white p-4 w-full py-2 rounded-lg mt-2 border border-black" value={city} />
                                    </div>
                                    <div>
                                        <div>State</div>
                                        <input onChange={(e) => handleInputChange(e, setHomeState)} className="bg-white p-4 w-full py-2 rounded-lg mt-2 border border-black" value={homeState} />
                                    </div>
                                    <div>
                                        <div>Country</div>
                                        <input onChange={(e) => handleInputChange(e, setCountry)} className="bg-white p-4 w-full py-2 rounded-lg mt-2 border border-black" value={country} />
                                    </div>
                                </div>
                                <div className="my-2">
                                    <div>Phone number</div>
                                    <input onChange={(e) => handleInputChange(e, setPhone)} className="bg-white p-4 w-full py-2 rounded-lg mt-2 border border-black" value={phone} />
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
            <div className="w-full flex justify-center items-center bg-white border-t-2 border-t-gray-500 fixed bottom-0 z-10">
                <button
                    onClick={saveContactEdits}
                    className="mt-5 text-secondary hover:text-primary font-medium bg-primary hover:bg-secondary border-2 border-secondary hover:border-primary rounded-full px-3 py-2 w-3/4 sm:w-1/4 mb-3"
                >
                    Save changes
                </button>
            </div>
        </div>
    );
};

export default SMSettings;
