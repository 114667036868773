import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const IntakeRedirect = () => {
    const location = useLocation();

    useEffect(() => {
        // try navigate to a set formsort redirect component
        let formsortURL = `https://forms.cofertility.com/flow/family-intake-form/variant/main?user_id=${location.state?.userID}`;
        let env = process.env.REACT_APP_ENV;
        if (env === "local") {
            env = process.env.REACT_APP_STAFF_FIRST_NAME + "_" + env;
            formsortURL += `&formsortEnv=${env}`;
        } else if (env !== "prod") {
            formsortURL += `&formsortEnv=${env}`;
        }
        // to prevent double render navigate to a different page that then solely redirects to formsort
        window.location.href = formsortURL;
        return;
    });

    let logo = <img className="pb-2" height="127" width="165" alt="FreezeByCo logo" src={`${process.env.PUBLIC_URL}/images/logo.svg`} />;

    return (
        <div id="inner-body" className={`min-h-screen flex flex-col bg-white bg-cover bg-fixed bg-top`}>
            <header className="sm:py-8 sm:px-10 px-6 py-5  flex items-center bg-secondary justify-between">{logo}</header>
            <div id="app" className="pb-10 freeze flex items-center justify-center">
                <div className="absolute inset-0 flex justify-center items-center z-50">
                    <img width={65} height={65} src={`${process.env.PUBLIC_URL}/images/loading_spinner.gif`} alt="Loading..." />
                </div>
                <div role="alert">Loading intake...</div>
            </div>
        </div>
    );
};
