import React, { useContext, useEffect, useState } from "react";
import { matchStages, matchStatuses } from "../../../../util/statuses";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../../App";
import { FriendlySlugTranslation, redirectToFormsort } from "../../../../util/helpers";

const Overview = ({ userId, refreshHeader, setRefreshHeader, setShowNoteModal }) => {
    const [ip, setIP] = useState(null);
    const [staff, setStaff] = useState(null);
    const [refetchUser, setRefetchUser] = useState(1);
    const [intake, setIntake] = useState(null);

    const [personalInfoModal, setPersonalInfoModal] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [preferredName, setPreferredName] = useState("");
    const [pronouns, setPronouns] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState("");

    const [hasError, setHasError] = useState(null);

    const { request } = useContext(AppContext);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchOverview = async () => {
            try {
                const response = await request({
                    url: `/staff/intended-parents/${userId}/overview`,
                    method: "GET",
                });

                const { intendedParent, intake } = response;

                setIP(intendedParent);
                if (intendedParent.firstName) setFirstName(intendedParent.firstName);
                else setFirstName("");
                if (intendedParent.lastName) setLastName(intendedParent.lastName);
                else setLastName("");
                if (intendedParent.dateOfBirth) setDateOfBirth(intendedParent.dateOfBirth);
                else setDateOfBirth("");
                if (intendedParent.preferredName) setPreferredName(intendedParent.preferredName);
                else setPreferredName("");
                if (intendedParent.pronouns) setPronouns(intendedParent.pronouns);
                else setPronouns("");
                setIntake(intake);
            } catch (error) {
                // TODO: handle this error
                console.error("Error fetching checklist:", error);
                setHasError(error);
            }
        };

        fetchOverview();
    }, [userId, request, refetchUser]);

    useEffect(() => {
        if (hasError) {
            throw hasError;
        }
    }, [hasError]);

    const calculateAge = (dateOfBirth) => {
        if (!dateOfBirth) {
            return "";
        }

        const today = new Date();
        const birthDate = new Date(dateOfBirth);

        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();

        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        return age;
    };

    const extractDate = (dateTimeString) => {
        const dateParts = dateTimeString.split("T")[0]; // Split on 'T' and take the first part
        return dateParts;
    };

    const handleString = (s) => {
        if (!s) {
            return "";
        }

        if (s in FriendlySlugTranslation) {
            return FriendlySlugTranslation[s];
        }

        if (s.includes("_")) {
            return s
                .split("_")
                .map((word, i) => {
                    if (i === 0) {
                        return word[0].toUpperCase() + word.substring(1);
                    }

                    return word;
                })
                .join(" ");
        } else if (s.length > 1) {
            return s[0].toUpperCase() + s.substring(1);
        }

        return s;
    };

    const shorthandDate = (dateTimeString) => {
        const options = { weekday: "short", year: "numeric", month: "short", day: "numeric" };
        const formattedDate = new Date(dateTimeString).toLocaleDateString("en-US", options);
        return formattedDate;
    };

    const navigateToMatchPage = (userId) => {
        navigate(`/staff/split-members/${userId}`);
    };

    const modal = (options) => {
        return (
            <div
                className="modal"
                style={{
                    position: "fixed",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <div
                    className="modal-content w-full max-w-3xl"
                    style={{
                        backgroundColor: "white",
                        padding: "40px",
                        borderRadius: "5px",
                        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.3)",
                    }}
                >
                    <h3 className="text-2xl font-semibold mb-4">Edit {options.title}</h3>
                    <div className="grid grid-cols-2 gap-x-6 gap-y-4 rounded-md p-4">
                        {options.fields.map((field) => {
                            return (
                                <div>
                                    <label className="text-sm font-medium">{field.name}:</label>
                                    <input
                                        className="form-input mt-1 w-full border border-gray-300 rounded-lg px-2"
                                        onChange={(event) => handleInputChange(event, field.setState)}
                                        value={field.value}
                                    />
                                </div>
                            );
                        })}

                        <div className="col-span-2 flex justify-end mt-4">
                            <button onClick={options.close} className="mr-2 px-4 py-2 rounded-md bg-gray-300 hover:bg-gray-400">
                                Close
                            </button>
                            <button onClick={options.save} className="px-4 py-2 rounded-md bg-blue-500 hover:bg-blue-600 text-white">
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const handleInputChange = (event, setState) => {
        setState(event.target.value);
    };

    const closePersonalInfo = () => {
        if (ip.firstName) setFirstName(ip.firstName);
        else setFirstName("");
        if (ip.lastName) setLastName(ip.lastName);
        else setLastName("");
        if (ip.dateOfBirth) setDateOfBirth(ip.dateOfBirth);
        else setDateOfBirth("");
        if (ip.preferredName) setPreferredName(ip.preferredName);
        else setPreferredName("");
        if (ip.pronouns) setPronouns(ip.pronouns);
        else setPronouns("");
        setPersonalInfoModal(false);
    };

    const savePersonalInfo = async () => {
        try {
            await request({
                url: `/staff/intended-parents/${userId}/personal-info`,
                method: "PUT",
                data: {
                    firstName,
                    lastName,
                    preferredName,
                    pronouns,
                    dateOfBirth,
                },
            });

            setRefetchUser(refetchUser + 1);
            setRefreshHeader(refreshHeader + 1);
            setPersonalInfoModal(false);
        } catch (err) {
            console.log(err);
        }
    };

    const pinNote = async (note) => {
        try {
            await request({
                url: `/staff/intended-parents/${ip.id}/pin-note`,
                method: "PUT",
                data: {
                    noteID: note.id,
                    pinned: !note.pinned,
                },
            });

            setRefetchUser(refetchUser + 1);
        } catch (err) {
            console.log(err);
        }
    };

    const formatPhoneNumber = (phoneNumberString) => {
        const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `${match[1]}-${match[2]}-${match[3]}`;
        }
        return null;
    };

    return ip ? (
        <div>
            <div className="flex mx-30 mb-10">
                <div className="w-2/3 mr-5">
                    <div className="col-span-4 border border-gray-300 rounded-md flex flex-col divide-y divide-dashed mb-5">
                        <div className="flex justify-between items-center col-span-2 px-5 pt-3">
                            <h3 className="heading-underline-goldenrod font-medium mr-5">Personal Info</h3>
                            <span onClick={() => redirectToFormsort({ form: intake, user: ip, staff: staff, newTab: true })} className="text-secondary flex items-center cursor-pointer">
                                <div className="inline-block mr-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                                        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                                    </svg>
                                </div>
                                Edit
                            </span>
                        </div>
                        <div className="flex flex-col py-7 lg:grid grid-cols-2 gap-x-6 gap-y-4 rounded-md h-full self-stretch -m-px">
                            <div className="flex items-center col-span-2 mx-5">
                                <span className="w-2/5 text-sm mr-5">Name</span>
                                <span className="w-3/5 text-sm font-semibold">
                                    {ip.firstName ? ip.firstName : ""} {ip.lastName ? ip.lastName : ""}
                                </span>
                            </div>

                            <div className="flex items-center col-span-2 mx-5">
                                <span className="w-2/5 text-sm mr-5">Preferred Name</span>
                                <span className="w-3/5 text-sm font-semibold">
                                    {ip.preferredName ? handleString(ip.preferredName) : <span className="italic text-gray-300 font-light">Empty</span>}
                                </span>
                            </div>

                            <div className="flex items-center col-span-2 mx-5">
                                <span className="w-2/5 text-sm mr-5">Pronouns</span>
                                <span className="w-3/5 text-sm font-semibold">{ip.pronouns ? handleString(ip.pronouns) : <span className="italic text-gray-300 font-light">Empty</span>}</span>
                            </div>

                            <div className="flex items-center col-span-2 mx-5">
                                <span className="w-2/5 text-sm mr-5">Date of Birth</span>
                                <span className="w-3/5 text-sm">
                                    <span className="font-semibold">{ip.dateOfBirth ? extractDate(ip.dateOfBirth) : <span className="italic text-gray-300 font-light">Empty</span>}</span>{" "}
                                    {ip.dateOfBirth ? `(${calculateAge(ip.dateOfBirth)} years old)` : ""}
                                </span>
                            </div>

                            {ip.intended_parent.family.other_guardian ? (
                                <div className="flex items-center col-span-2 mx-5">
                                    <span className="w-2/5 text-sm mr-5">Partner First Name</span>
                                    {ip.intended_parent.partner_first_name ? (
                                        <span className="w-3/5 text-sm font-semibold">{handleString(ip.intended_parent.partner_first_name)}</span>
                                    ) : (
                                        <span className="italic text-gray-300 font-normal">Empty</span>
                                    )}
                                </div>
                            ) : null}

                            {ip.intended_parent.family.other_guardian ? (
                                <div className="flex items-center col-span-2 mx-5">
                                    <span className="w-2/5 text-sm mr-5">Partner Last Name</span>
                                    {ip.intended_parent.partner_last_name ? (
                                        <span className="w-3/5 text-sm font-semibold">{handleString(ip.intended_parent.partner_last_name)}</span>
                                    ) : (
                                        <span className="italic text-gray-300 font-normal">Empty</span>
                                    )}
                                </div>
                            ) : null}

                            {ip.intended_parent.family.other_guardian ? (
                                <div className="flex items-center col-span-2 mx-5">
                                    <span className="w-2/5 text-sm mr-5">Partner Pronouns</span>
                                    {ip.intended_parent.partner_pronouns ? (
                                        <span className="w-3/5 text-sm font-semibold">
                                            {handleString(
                                                ip.intended_parent.partner_pronouns === "other" && ip.intended_parent.partner_other_pronouns
                                                    ? ip.intended_parent.partner_other_pronouns
                                                    : ip.intended_parent.partner_pronouns
                                            )}
                                        </span>
                                    ) : (
                                        <span className="italic text-gray-300 font-normal">Empty</span>
                                    )}
                                </div>
                            ) : null}

                            {ip.intended_parent.family.other_guardian ? (
                                <div className="flex items-center col-span-2 mx-5">
                                    <span className="w-2/5 text-sm mr-5">Partner DOB</span>
                                    {ip.intended_parent.partner_dob ? (
                                        <span className="w-3/5 text-sm font-semibold">{extractDate(ip.intended_parent.partner_dob)}</span>
                                    ) : (
                                        <span className="italic text-gray-300 font-normal">Empty</span>
                                    )}
                                </div>
                            ) : null}

                            {ip.intended_parent.family.other_guardian ? (
                                <div className="flex items-center col-span-2 mx-5">
                                    <span className="w-2/5 text-sm mr-5">Partner Email</span>
                                    {ip.intended_parent.partner_email ? (
                                        <span className="w-3/5 text-sm font-semibold">{handleString(ip.intended_parent.partner_email)}</span>
                                    ) : (
                                        <span className="italic text-gray-300 font-normal">Empty</span>
                                    )}
                                </div>
                            ) : null}

                            {ip.intended_parent.family.other_guardian ? (
                                <div className="flex items-center col-span-2 mx-5">
                                    <span className="w-2/5 text-sm mr-5">Partner Phone</span>
                                    {ip.intended_parent.partner_phone ? (
                                        <span className="w-3/5 text-sm font-semibold">{formatPhoneNumber(ip.intended_parent.partner_phone)}</span>
                                    ) : (
                                        <span className="italic text-gray-300 font-normal">Empty</span>
                                    )}
                                </div>
                            ) : null}
                        </div>
                    </div>

                    <div className="col-span-4 border border-gray-300 rounded-md flex flex-col divide-y divide-dashed mb-5">
                        <div className="flex justify-between items-center col-span-2 px-5 pt-3">
                            <h3 className="heading-underline-goldenrod font-medium mr-5">Match Preferences & Availability</h3>
                            <span onClick={() => redirectToFormsort({ form: intake, user: ip, staff: staff, newTab: true })} className="text-secondary flex items-center cursor-pointer">
                                <div className="inline-block mr-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                                        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                                    </svg>
                                </div>
                                Edit
                            </span>
                        </div>
                        <div className="flex flex-col py-7 lg:grid grid-cols-2 gap-x-6 gap-y-4 rounded-md h-full self-stretch -m-px">
                            <div className="flex items-center col-span-2 mx-5">
                                <span className="w-2/5 text-sm mr-5">Cycle Type</span>
                                <span className="w-3/5 text-sm font-semibold">
                                    {ip.intended_parent.family.fresh_or_frozen ? (
                                        handleString(ip.intended_parent.family.fresh_or_frozen)
                                    ) : (
                                        <span className="italic text-gray-300 font-normal">Empty</span>
                                    )}
                                </span>
                            </div>

                            <div className="flex items-center col-span-2 mx-5">
                                <p className="w-2/5 text-sm mr-5">Timeframe</p>
                                <p className="w-3/5 text-sm font-semibold">
                                    {ip.intended_parent.family.ideal_timeframe ? (
                                        handleString(ip.intended_parent.family.ideal_timeframe)
                                    ) : (
                                        <span className="italic text-gray-300 font-normal">Empty</span>
                                    )}
                                </p>
                            </div>

                            <div className="flex items-center col-span-2 mx-5">
                                <p className="w-2/5 text-sm mr-5">Relationship Type</p>
                                <p className="w-3/5 text-sm font-semibold">
                                    {ip.intended_parent.family.donor_relationship ? (
                                        handleString(ip.intended_parent.family.donor_relationship)
                                    ) : (
                                        <span className="italic text-gray-300 font-normal">Empty</span>
                                    )}
                                </p>
                            </div>

                            <div className="flex items-center col-span-2 mx-5">
                                <p className="w-2/5 text-sm mr-5">Donor Preference</p>
                                <p className="w-3/5 text-sm font-semibold">
                                    {ip.intended_parent.family.donor_preferences ? (
                                        handleString(ip.intended_parent.family.donor_preferences)
                                    ) : (
                                        <span className="italic text-gray-300 font-normal">Empty</span>
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="col-span-4 border border-gray-300 rounded-md flex flex-col divide-y divide-dashed max-h-[300px] pb-5">
                        <div className="flex justify-between items-center col-span-2 px-5 pt-3">
                            <h3 className="heading-underline-goldenrod font-medium mr-5">Notes</h3>
                            <span onClick={() => setShowNoteModal(true)} className="text-secondary">
                                + Add Note
                            </span>
                        </div>
                        <div className={`rounded-md px-6 py-2 h-full self-stretch -m-px overflow-y-auto min-h-16 ${!ip.notes ? "flex items-center" : ""}`}>
                            {ip.notes ? (
                                ip.notes
                                    .sort((a, b) => {
                                        if (a.pinned !== b.pinned) {
                                            return a.pinned ? -1 : 1;
                                        }

                                        return new Date(b.created_at) - new Date(a.created_at);
                                    })
                                    .map((note) => (
                                        <div key={note.id} className={`flex my-3 bg-gray-100 rounded-md ${note.pinned ? "bg-yellow-100" : "bg-gray-100"} pt-3 pl-3`}>
                                            <div className="w-7 mr-2 mt-1 flex">
                                                <h1 className="flex items-center justify-center w-7 h-7 rounded-full px-2 py-1 text-black text-sm" style={{ backgroundColor: "#D2E9E6" }}>
                                                    {note.staff.firstName.charAt(0).toUpperCase()}
                                                </h1>
                                            </div>
                                            <div className="flex-grow flex flex-col text-sm">
                                                <div className="flex justify-between items-center">
                                                    <span>
                                                        <span className="font-semibold ">
                                                            {note.staff.firstName} {note.staff.lastName[0]}.
                                                        </span>
                                                        <span className="text-gray-400 ml-2">| Note</span>
                                                    </span>
                                                    <span className="mr-3 text-gray-400">
                                                        <span className="mr-2">
                                                            <button onClick={() => pinNote(note)} className="focus:outline-none">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    className={`h-3 w-3 fill-current ${note.pinned ? "text-yellow-600" : "text-gray-500"} hover:text-yellow-600`}
                                                                    viewBox="0 0 24 24"
                                                                >
                                                                    <path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z" />
                                                                </svg>
                                                            </button>
                                                        </span>
                                                        {extractDate(note.created_at)}
                                                    </span>
                                                </div>
                                                <div className="pt-2 pb-4 whitespace-pre-line">{note.note}</div>
                                            </div>
                                        </div>
                                    ))
                            ) : (
                                <div className="italic text-gray-300">None</div>
                            )}
                        </div>
                    </div>
                </div>

                <div className="w-1/3">
                    <div className="col-span-4 border border-gray-300 rounded-md flex flex-col divide-y divide-dashed mb-5">
                        <div className="flex justify-between items-center col-span-2 px-5 pt-3">
                            <h3 className="heading-underline-goldenrod font-medium mr-5">Clinic</h3>
                            <span onClick={() => redirectToFormsort({ form: intake, user: ip, staff: staff, newTab: true })} className="text-secondary flex items-center cursor-pointer">
                                <div className="inline-block mr-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                                        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                                    </svg>
                                </div>
                                Edit
                            </span>
                        </div>
                        <div className="flex flex-col py-7 lg:grid grid-cols-2 gap-x-6 gap-y-4 rounded-md h-full self-stretch -m-px">
                            <div className="flex items-center col-span-2 mx-5">
                                <p className="w-2/5 text-sm mr-5">Clinic Name</p>
                                <p className="w-3/5 text-sm font-semibold">
                                    {ip.intended_parent.family.intake_clinic ? handleString(ip.intended_parent.family.intake_clinic) : <span className="italic text-gray-300 font-normal">Empty</span>}
                                </p>
                            </div>

                            <div className="flex items-center col-span-2 mx-5">
                                <p className="w-2/5 text-sm mr-5">Doctor</p>
                                <p className="w-3/5 text-sm font-semibold">
                                    {ip.intended_parent.family.intake_doctor ? handleString(ip.intended_parent.family.intake_doctor) : <span className="italic text-gray-300 font-normal">Empty</span>}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="col-span-4 border border-gray-300 rounded-md flex flex-col divide-y divide-dashed mb-5">
                        <div className="flex justify-between items-center col-span-2 px-5 pt-3">
                            <h3 className="heading-underline-goldenrod font-medium mr-5">Finance</h3>
                        </div>
                        <div className="flex pb-7 h-full self-stretch mx-5">
                            <table className="table-fixed w-full divide-y divide-dashed">
                                <tbody>
                                    <tr key="total">
                                        <td className="w-1/2 text-left py-2 border-b border-dashed border-gray-300">Cofertility Total</td>
                                        <td className="w-1/2 text-left py-2 border-b border-dashed border-gray-300">
                                            <span className="italic text-gray-300 font-normal">Empty</span>
                                        </td>
                                    </tr>
                                    <tr key="escrow">
                                        <td className="w-1/2 text-left py-2 border-b border-dashed border-gray-300">Escrow Funded</td>
                                        <td className="w-1/2 text-left py-2 border-b border-dashed border-gray-300">
                                            <span className="italic text-gray-300 font-normal">Empty</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className={`col-span-4 border border-gray-300 rounded-md flex flex-col mb-5 ${ip.intended_parent.family.matches ? "bg-[#D2E9E6]" : ""}`}>
                        <div className="flex justify-between items-center col-span-2 px-5 pt-3">
                            <h3 className="font-medium mr-5">
                                <span className="heading-underline-forest-50 mr-2">Match History</span>({ip.intended_parent.family.matches ? ip.intended_parent.family.matches.length : 0})
                            </h3>
                        </div>
                        <div
                            className={`rounded-md px-6 py-2 h-full self-stretch -m-px overflow-y-auto ${
                                !ip.intended_parent.family.matches ? "flex items-center min-h-16" : "min-h-[250px] max-h-[490px]"
                            }`}
                        >
                            {ip.intended_parent.family.matches ? (
                                ip.intended_parent.family.matches
                                    .sort((a, b) => {
                                        if (a.status === "cancelled" && b.status !== "cancelled") {
                                            return 1;
                                        }

                                        return -1;
                                    })
                                    .map((match) => (
                                        <div key={match.id} className={`flex w-full mb-4 rounded-lg border border-solid border-black bg-white box-border`}>
                                            <div className="flex-grow flex flex-col text-sm">
                                                <div className="flex justify-between items-center p-3">
                                                    <div className="flex">
                                                        <span className="text-xs text-secondary">
                                                            <span
                                                                onClick={() => navigate(`/staff/matches/${match.id}`)}
                                                                className="text-secondary text-sm font-normal mx-2 cursor-pointer flex items-center"
                                                            >
                                                                #{match.match_number}
                                                            </span>
                                                        </span>
                                                        <span className="text-xs text-secondary">
                                                            <span className="text-secondary text-sm font-normal mx-2 cursor-pointer flex items-center">
                                                                CRM
                                                                <div className="inline-block ml-2">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="16"
                                                                        height="16"
                                                                        fill="currentColor"
                                                                        className="bi bi-arrow-up-right"
                                                                        viewBox="0 0 16 16"
                                                                    >
                                                                        <path
                                                                            fillRule="evenodd"
                                                                            d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"
                                                                        />
                                                                    </svg>
                                                                </div>
                                                            </span>
                                                        </span>
                                                    </div>

                                                    <div>
                                                        <span className="text-gray-400 ml-2">
                                                            <span
                                                                className="text-secondary px-2 py-1 rounded-md borde w-1/2 font-medium text-xs"
                                                                style={{
                                                                    backgroundColor: matchStatuses.filter((status) => status.value === match.status)[0].bg,
                                                                    color: matchStatuses.filter((status) => status.value === match.status)[0].color,
                                                                }}
                                                            >
                                                                {matchStatuses.filter((status) => status.value === match.status)[0].label}
                                                            </span>
                                                        </span>
                                                        <span className="text-gray-400 ml-2">
                                                            <span
                                                                className="text-secondary px-2 py-1 rounded-md borde w-1/2 font-medium text-xs"
                                                                style={{
                                                                    backgroundColor: matchStages.filter((status) => status.value === match.stage)[0].bg,
                                                                    color: matchStages.filter((status) => status.value === match.stage)[0].color,
                                                                }}
                                                            >
                                                                {matchStages.filter((status) => status.value === match.stage)[0].label}
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="border-b border-solid border-black w-full"></div>
                                                <div className="w-full mt-4 flex justify-center items-center">
                                                    <div className="inline-block mx-2">
                                                        <h1 className="flex items-center justify-center w-16 h-16 rounded-full px-2 py-1 text-black text-lg" style={{ backgroundColor: "#D2E9E6" }}>
                                                            {ip.firstName.charAt(0).toUpperCase()}
                                                        </h1>
                                                        <div>
                                                            {ip.firstName} {ip.lastName}
                                                        </div>
                                                    </div>
                                                    <div onClick={() => navigateToMatchPage(match.freeze_member_id)} className="inline-block mx-2 cursor-pointer">
                                                        <h1 className="flex items-center justify-center w-16 h-16 rounded-full px-2 py-1 text-black text-lg" style={{ backgroundColor: "#D2E9E6" }}>
                                                            {match.freeze_member.firstName.charAt(0).toUpperCase()}
                                                        </h1>
                                                        <div>{match.freeze_member.donorCode}</div>
                                                    </div>
                                                </div>
                                                <div className="border-b border-dashed border-gray-500 w-full mt-4"></div>
                                                <div className="flex flex-col py-4 gap-x-2 gap-y-2">
                                                    <div className="flex items-center justify-center col-span-2 mx-5">
                                                        <p className="w-1/3 text-left text-sm mr-2">Match Date</p>
                                                        <p className="w-1/3 text-left text-sm">{shorthandDate(match.created_at)}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                            ) : (
                                <div className="italic text-gray-300">None</div>
                            )}
                        </div>
                    </div>

                    <div className="col-span-4 border border-gray-300 rounded-md flex flex-col divide-y divide-dashed">
                        <div className="flex justify-between items-center col-span-2 px-5 pt-3">
                            <h3 className="heading-underline-goldenrod font-medium mr-5">Communications</h3>
                            <span onClick={() => redirectToFormsort({ form: intake, user: ip, staff: staff, newTab: true })} className="text-secondary flex items-center cursor-pointer">
                                <div className="inline-block mr-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                                        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                                    </svg>
                                </div>
                                Edit
                            </span>
                        </div>
                        <div className="flex flex-col py-7 lg:grid grid-cols-2 gap-x-6 gap-y-4 rounded-md h-full self-stretch -m-px">
                            <div className="flex items-center col-span-2 mx-5">
                                <p className="w-2/5 text-sm mr-5">Texting</p>
                                <p className="w-3/5 text-sm font-semibold">
                                    {intake && intake.application && intake.application.response && intake.application.response.answers.sms_confirmation ? "Yes" : "No"}
                                </p>
                            </div>

                            <div className="flex items-center col-span-2 mx-5">
                                <p className="w-2/5 text-sm mr-5">Referral Source</p>
                                <p className="w-3/5 text-sm font-semibold">
                                    {intake && intake.application && intake.application.response ? (
                                        handleString(intake.application.response.answers.how_did_you_hear_about_us)
                                    ) : (
                                        <span className="italic text-gray-300 font-normal">Empty</span>
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {personalInfoModal
                ? modal({
                      title: "Personal Info",
                      close: closePersonalInfo,
                      save: savePersonalInfo,
                      fields: [
                          { name: "First Name", value: firstName, setState: setFirstName },
                          { name: "Last Name", value: lastName, setState: setLastName },
                          { name: "Preferred Name", value: preferredName, setState: setPreferredName },
                          { name: "Pronouns", value: pronouns, setState: setPronouns },
                          { name: "Date of Birth", value: extractDate(dateOfBirth), setState: setDateOfBirth },
                      ],
                  })
                : null}
        </div>
    ) : null;
};

export default Overview;
