import React from 'react';
import { AddressElement, ExpressCheckoutElement, PaymentElement, useCheckout } from '@stripe/react-stripe-js';

const CheckoutForm = () => {
    const checkout = useCheckout();
    const handleExpressCheckoutReady = ({ availablePaymentMethods }) => {
        if (availablePaymentMethods) {
            const elements = document.querySelectorAll('.divider');
            elements.forEach((item, index) => {
                item.classList.remove('!hidden');
            })
        }
    }
    return (
        <form>
            <ExpressCheckoutElement className='stripe-express-checkout' onReady={handleExpressCheckoutReady} />
            <div class="divider !hidden">
                <span className="text-neutral-700">OR</span>
            </div>
            <AddressElement options={{ mode: 'billing' }} className='stripe-address mb-2' />
            <PaymentElement options={{ layout: 'accordion' }} className='stripe-payment my-4' />
        </form>
    )
};
export default CheckoutForm
