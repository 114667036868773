import React, { useEffect, useState, useRef, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Overview from "./Overview";
import Profile from "./Profile";
import Documents from "../../files/Documents";
import Notes from "../../notes/Notes";
import UploadGallery from "../../files/Photos/UploadGallery";
import AuditLogs from "../../audit_logs/AuditLogs";
import Activity from "./Activity";
import { agreementStatuses, amhStatuses, callStatuses, fmStatuses, migrationStatuses, scStatuses } from "../../../../util/statuses";
import CreateMatchModal from "../../matches/CreateMatchModal";
import { SimpleModal } from "../../../modals/SimpleModal";
import { DisqualificatinReasons, calculateLogTime, extractDate, handleCopy } from "../../../../util/helpers";
import SMAdminChecklist from "./Checklist";
import { AppContext } from "../../../../App";

const SplitMemberPage = ({ tab, subtab }) => {
    const [currentTab, setCurrentTab] = useState(tab);
    const [taskTab, setTaskTab] = useState("now");
    const [user, setUser] = useState(null);
    const [checklist, setChecklist] = useState(null);
    const [profilePhoto, setProfilePhoto] = useState(null);
    const [currentPhase, setCurrentPhase] = useState(null);
    const [refetchUser, setRefetchUser] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [stickyTabs, setStickyTabs] = useState(false);
    const [pdfOptions, setPDFOptions] = useState(false);
    const [confirmInvite, setConfirmInvite] = useState(false);
    const [newDonorDigest, setNewDonorDigest] = useState(false);
    const [pendingStatus, setPendingStatus] = useState(null);

    const [statusConfirmationFunc, setStatusConfirmationFunc] = useState(null);
    const [statusConfirmationText, setStatusConfirmationText] = useState(null);

    const [fmStatusDropdownOpen, setFMStatusDropdownOpen] = useState(false);
    const [amhStatusDropdownOpen, setAMHStatusDropdownOpen] = useState(false);
    const [scStatusDropdownOpen, setSCStatusDropdownOpen] = useState(false);
    const [callStatusDropdownOpen, setCallStatusDropdownOpen] = useState(false);
    const [agreementStatusDropdownOpen, setAgreementStatusDropdownOpen] = useState(false);

    const [disqualModal, setDisqualModal] = useState(false);
    const [disqualReasons, setDisqualReasons] = useState([]);
    const [disqualSearchInput, setDisqualSearchInput] = useState("");
    const [filterFocus, setFilterFocus] = useState(false);
    const disqualDropdownRef = useRef(null);

    const [showTaskCenter, setShowTaskCenter] = useState(false);
    const [matchModal, setMatchModal] = useState(false);

    const [showNoteModal, setShowNoteModal] = useState(false);
    const [noteText, setNoteText] = useState("");
    const [toggleNotePinned, setToggleNotePinned] = useState(false);

    const [showContactModal, setShowContactModal] = useState(false);
    const [refreshKey, setRefreshKey] = useState(0);

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [preferredName, setPreferredName] = useState("");
    const [pronouns, setPronouns] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [city, setCity] = useState("");
    const [homeState, setHomeState] = useState("");
    const [country, setCountry] = useState("");
    const [zipcode, setZipcode] = useState("");
    const [appStartedDate, setAppStartedDate] = useState("");

    const [hasError, setHasError] = useState(null);
    const [copiedEmail, setCopiedEmail] = useState(false);
    const [copiedID, setCopiedID] = useState(false);

    const { request } = useContext(AppContext);
    const navigate = useNavigate();
    const { userId } = useParams();
    const tabBarRef = useRef(null);
    const taskCenterRef = useRef(null);
    const fmStatusRef = useRef(null);
    const amhStatusRef = useRef(null);
    const scStatusRef = useRef(null);
    const callStatusRef = useRef(null);
    const agreementStatusRef = useRef(null);
    const pdfRef = useRef(null);

    useEffect(() => {
        const fetchSplitMember = async () => {
            try {
                // remove this GET request all together, we should have all the needed info within local user storage
                // once we update the prescreener and general questions logic to update to user record
                const response = await request({
                    url: `/staff/split-members/${userId}`,
                    method: "GET",
                });

                const { splitMember, checklist, photo, appStartedDate } = response;

                if (splitMember.flags && splitMember.flags.length) {
                    const counts = {
                        green: 0,
                        yellow: 0,
                        orange: 0,
                        red: 0,
                    };

                    splitMember.flags.forEach((flag) => {
                        if (counts[flag.color]) {
                            counts[flag.color]++;
                        }
                    });

                    splitMember.greenFlags = counts.green;
                    splitMember.yellowFlags = counts.yellow;
                    splitMember.orangeFlags = counts.orange;
                    splitMember.redFlags = counts.red;
                }

                setUser(splitMember);
                setFirstName(splitMember.firstName);
                setLastName(splitMember.lastName);
                setEmail(splitMember.email);
                if (splitMember.preferredName) setPreferredName(splitMember.preferredName);
                if (splitMember.pronouns) setPronouns(splitMember.pronouns);
                if (splitMember.phoneNumber) setPhone(splitMember.phoneNumber);
                if (splitMember.address1) setAddress1(splitMember.address1);
                if (splitMember.address2) setAddress2(splitMember.address2);
                if (splitMember.city) setCity(splitMember.city);
                if (splitMember.state) setHomeState(splitMember.state);
                if (splitMember.country) setCountry(splitMember.country);
                if (splitMember.zipcode) setZipcode(splitMember.zipcode);

                if (splitMember.freeze_member.disqual_reason) setDisqualReasons(splitMember.freeze_member.disqual_reason.split(";"));

                setChecklist(checklist);
                setProfilePhoto(photo);
                setAppStartedDate(appStartedDate);
                const currentPhase = checklist.checklist_phases.filter((phase) => phase.name === splitMember.freeze_member.status)[0];
                setCurrentPhase(currentPhase);
                setIsLoading(false);
                setRefreshKey((prev) => prev + 1);
                document.title = `Staff | ${splitMember.firstName} ${splitMember.lastName[0]}. | ${tab[0].toUpperCase() + tab.substring(1)}`;
            } catch (error) {
                // TODO: handle this error
                console.error("Error fetching checklist:", error);
                setHasError(error);
            }
        };

        fetchSplitMember();
    }, [userId, tab, request, refetchUser]);

    useEffect(() => {
        if (hasError) {
            throw hasError;
        }
    }, [hasError]);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (disqualDropdownRef.current && !disqualDropdownRef.current.contains(event.target)) {
                setFilterFocus(false);
            }
        };

        document.addEventListener("click", handleOutsideClick);

        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (pdfRef.current && !pdfRef.current.contains(event.target)) {
                setPDFOptions(false);
            }
        };

        document.addEventListener("click", handleOutsideClick);

        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (fmStatusRef.current && !fmStatusRef.current.contains(event.target)) {
                setFMStatusDropdownOpen(false);
            }
        };

        document.addEventListener("click", handleOutsideClick);

        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (amhStatusRef.current && !amhStatusRef.current.contains(event.target)) {
                setAMHStatusDropdownOpen(false);
            }
        };

        document.addEventListener("click", handleOutsideClick);

        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (scStatusRef.current && !scStatusRef.current.contains(event.target)) {
                setSCStatusDropdownOpen(false);
            }
        };

        document.addEventListener("click", handleOutsideClick);

        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (callStatusRef.current && !callStatusRef.current.contains(event.target)) {
                setCallStatusDropdownOpen(false);
            }
        };

        document.addEventListener("click", handleOutsideClick);

        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (agreementStatusRef.current && !agreementStatusRef.current.contains(event.target)) {
                setAgreementStatusDropdownOpen(false);
            }
        };

        document.addEventListener("click", handleOutsideClick);

        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (taskCenterRef.current && !taskCenterRef.current.contains(event.target)) {
                setShowTaskCenter(false);
            }
        };

        document.addEventListener("click", handleOutsideClick);

        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            if (tabBarRef.current) {
                const rect = tabBarRef.current.getBoundingClientRect();

                const shouldBeSticky = rect.top <= 0;

                // Update the state ONLY if it has changed
                if (shouldBeSticky !== stickyTabs) {
                    setStickyTabs(shouldBeSticky);
                    setShowTaskCenter(false);
                }
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [stickyTabs]);

    const setUserStatus = async (type, status, dropdownOpen, newDonorDigest) => {
        if (type === "status" && status === "disqualified") {
            // open disqualification modal, from here can cancel to return to status before disqualification was clicked or enter a reason and set the disqualification status
            dropdownOpen(false);
            setDisqualModal(true);
            return;
        }

        try {
            await request({
                url: `/staff/split-members/${userId}/status`,
                method: "PUT",
                data: {
                    type: type,
                    status,
                    digest: newDonorDigest,
                },
            });

            setRefetchUser((prevKey) => prevKey + 1);
            dropdownOpen(false);
            setPendingStatus(null);
            setNewDonorDigest(false);
            setStatusConfirmationFunc(null);
            setStatusConfirmationText(null);
        } catch (error) {
            // TODO: handle this error
            console.error("Error updating user status:", error);
        }
    };

    const changeTab = (tabName) => {
        setCurrentTab(tabName);
        window.history.replaceState(null, "", `/staff/split-members/${userId}/${tabName}`);
        document.title = `Staff | ${user.firstName} ${user.lastName[0]}. | ${tabName[0].toUpperCase() + tabName.substring(1)}`;
        if (stickyTabs) {
            tabBarRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    if (isLoading) {
        return <div></div>;
    }

    const configureStatusConfirmation = (currentStatus, newStatus, statusList, abraUpdates = [], iterableEvents = [], statusType, setDropdown) => {
        let confirmation = [];
        confirmation.push(<div>Confirm you wish to change this status. Please note that some status changes trigger email events.</div>);
        confirmation.push(<br />);

        confirmation.push(
            <div className="flex items-center font-semibold">
                <div>{statusList.filter((s) => s.value === currentStatus)[0].label}</div>
                <div className="mx-4">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
                    </svg>
                </div>
                <div>{statusList.filter((s) => s.value === newStatus)[0].label}</div>
            </div>
        );

        if (abraUpdates.length) {
            confirmation.push(<br />);
            confirmation.push(<div className="font-semibold">Abra updates:</div>);
            abraUpdates.forEach((x) => confirmation.push(<div>- {x}</div>));
        }

        if (iterableEvents.length) {
            confirmation.push(<br />);
            confirmation.push(<div className="font-semibold">Iterable updates:</div>);
            iterableEvents.forEach((x) => confirmation.push(<div>- {x}</div>));
        }

        setDropdown(false);
        setPendingStatus(newStatus);
        setStatusConfirmationText(confirmation);
        setStatusConfirmationFunc(() => (newDonorDigest) => setUserStatus(statusType, newStatus, setDropdown, newDonorDigest));
    };

    const handleNoteChange = (event) => {
        setNoteText(event.target.value);
    };

    const closeNote = () => {
        setNoteText("");
        setToggleNotePinned(false);
        setShowNoteModal(false);
    };

    const saveNote = async () => {
        try {
            await request({
                url: `/staff/split-members/${userId}/note`,
                method: "POST",
                data: {
                    note: noteText,
                    pinned: toggleNotePinned,
                },
            });

            closeNote();
            setRefreshKey((prevKey) => prevKey + 1);
        } catch (err) {
            console.log(err);
        }
    };

    const sendAbraInvite = async () => {
        try {
            await request({
                url: `/send-invite`,
                method: "POST",
                data: {
                    email: user.email,
                    user_id: user.id,
                },
            });

            setConfirmInvite(false);
            setRefetchUser((prevKey) => prevKey + 1);
        } catch (err) {
            console.log(err);
        }
    };

    const formatPhoneNumber = (phoneNumberString) => {
        const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `${match[1]}-${match[2]}-${match[3]}`;
        }
        return null;
    };

    const handleInputChange = (event, setState) => {
        setState(event.target.value);
    };

    const closeContactModal = () => {
        setFirstName(user.firstName);
        setLastName(user.lastName);
        setEmail(user.email);
        if (user.preferredName) setPreferredName(user.preferredName);
        else setPreferredName("");
        if (user.pronouns) setPronouns(user.pronouns);
        else setPronouns("");
        if (user.phoneNumber) setPhone(user.phoneNumber);
        else setPhone("");
        if (user.address1) setAddress1(user.address1);
        else setAddress1("");
        if (user.address2) setAddress2(user.address2);
        else setAddress2("");
        if (user.city) setCity(user.city);
        else setCity("");
        if (user.state) setHomeState(user.state);
        else setHomeState("");
        if (user.country) setCountry(user.country);
        else setCountry("");
        if (user.zipcode) setZipcode(user.zipcode);
        else setZipcode("");
        setShowContactModal(false);
    };

    const saveContactEdits = async () => {
        try {
            await request({
                url: `/staff/split-members/${userId}/contact-info`,
                method: "PUT",
                data: {
                    firstName,
                    lastName,
                    preferredName,
                    pronouns,
                    phone,
                    email,
                    address1,
                    address2,
                    city,
                    state: homeState,
                    country,
                    zipcode,
                },
            });

            setRefetchUser(refetchUser + 1);
            setRefreshKey(refreshKey + 1);
            closeContactModal();
        } catch (err) {
            console.log(err);
        }
    };

    const completeTask = async (taskID, status) => {
        try {
            await request({
                url: `/checklist/tasks/${taskID}`,
                method: "PUT",
                data: {
                    taskID: taskID,
                    taskStatus: status,
                },
            });

            setRefetchUser((prevKey) => prevKey + 1);
        } catch (err) {
            console.log(err);
        }
    };

    const setDisqualification = async () => {
        try {
            await request({
                url: `/staff/split-members/${userId}/status/disqualify`,
                method: "PUT",
                data: {
                    reasons: disqualReasons,
                },
            });

            setRefetchUser((prevKey) => prevKey + 1);
            setDisqualModal(false);
        } catch (error) {
            // TODO: handle this error
            console.error("Error updating user status:", error);
        }
    };

    const publicProfileHREF = () => {
        const currentUrl = new URL(window.location.href);

        // Update the pathname of the URL
        currentUrl.pathname = `/staff/split-members/${userId}/public-profile`;

        return currentUrl.href;
    };

    const updateDisqualReason = (reason) => {
        const filteredReasons = structuredClone(disqualReasons).filter((selected) => selected !== reason);

        if (!disqualReasons.includes(reason)) {
            filteredReasons.push(reason);
        }

        setDisqualReasons(filteredReasons);
    };

    return (
        <div className="pb-20">
            <div className="divide-y divide-solid pb-10">
                <div className="flex flex-row w-1/2 pt-5 ml-10 mb-5">
                    <div onClick={() => navigate("/staff/split-members")} className="mr-2 cursor-pointer text-secondary text-sm font-medium">
                        SPLIT MEMBERS
                    </div>
                    <span className="text-sm font-medium">{">"}</span>
                    <div className="ml-2 text-secondary text-sm font-medium">
                        {user.firstName} {user.lastName}
                    </div>
                </div>
                <div></div>
            </div>

            <div className="flex p-4 mr-30">
                <div className="w-24 mr-2 flex justify-center">
                    <h1 className="relative w-20 h-20 rounded-full px-2 py-1 text-black text-3xl" style={{ backgroundColor: "#D2E9E6" }}>
                        {profilePhoto ? (
                            <img
                                src={profilePhoto.cropped_source ? profilePhoto.cropped_source : profilePhoto.source}
                                alt={profilePhoto.name}
                                className="absolute inset-0 w-full h-full object-cover rounded-full"
                            />
                        ) : (
                            <span className="flex items-center justify-center w-full h-full rounded-full">{user.firstName.charAt(0).toUpperCase()}</span>
                        )}
                    </h1>
                </div>

                <div className="flex-grow flex flex-col">
                    <div className="flex gap-2 items-center">
                        <h2>
                            {user.donorCode ? `[${user.donorCode}] ` : ""}
                            {user.firstName} {user.lastName} {user.preferredName ? `- "${user.preferredName}"` : ""} {user.pronouns ? `(${user.pronouns.split("_").join("/")})` : null}
                        </h2>
                    </div>

                    <div>
                        <div className="flex gap-1">
                            <div ref={fmStatusRef} className="align-left flex mb-1">
                                <div
                                    onClick={() => setFMStatusDropdownOpen(!fmStatusDropdownOpen)}
                                    className="h-7 text-secondary px-2 py-1 rounded-md border font-medium text-xs flex items-center"
                                    style={{
                                        backgroundColor: fmStatuses.filter((status) => status.value === user.freeze_member.status)[0].bg,
                                        color: fmStatuses.filter((status) => status.value === user.freeze_member.status)[0].color,
                                    }}
                                >
                                    <div className="inline-block mr-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clipboard" viewBox="0 0 16 16">
                                            <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z" />
                                            <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z" />
                                        </svg>
                                    </div>
                                    {fmStatuses.filter((status) => status.value === user.freeze_member.status)[0].label}
                                    <div className="inline-block ml-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                                            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    className="origin-top-right absolute min-w-min px-6 bg-white focus:outline-none shadow-xl rounded-lg overflow-hidden"
                                    style={fmStatusDropdownOpen ? { zIndex: 1000 } : { display: "none" }}
                                >
                                    <div>
                                        {fmStatuses.map((status) => (
                                            <div
                                                key={status.value}
                                                onClick={() => {
                                                    if (status.value === "disqualified") {
                                                        setUserStatus("status", status.value, setFMStatusDropdownOpen);
                                                    } else {
                                                        if (status.value === "active" && ["pp_under_review", "pp_revisions", "pp_qa", "on_hold", "matched"].includes(user.freeze_member.status)) {
                                                            setNewDonorDigest(true);
                                                        }
                                                        configureStatusConfirmation(
                                                            user.freeze_member.status,
                                                            status.value,
                                                            fmStatuses,
                                                            status.abraUpdates,
                                                            status.iterableEvents,
                                                            "status",
                                                            setFMStatusDropdownOpen
                                                        );
                                                    }
                                                }}
                                                className={`px-2 py-1 rounded-md font-medium text-xs my-2`}
                                                style={{ backgroundColor: status.bg, color: status.color }}
                                            >
                                                {status.label}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div ref={scStatusRef} className="align-left flex">
                                <div
                                    onClick={() => setSCStatusDropdownOpen(!scStatusDropdownOpen)}
                                    className="h-7 text-secondary px-2 py-1 rounded-md border font-medium text-xs flex items-center"
                                    style={{
                                        backgroundColor: scStatuses.filter((status) => status.value === user.freeze_member.cycle_first_status)[0].bg,
                                        color: scStatuses.filter((status) => status.value === user.freeze_member.cycle_first_status)[0].color,
                                    }}
                                >
                                    <div className="inline-block mr-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-snow" viewBox="0 0 16 16">
                                            <path d="M8 16a.5.5 0 0 1-.5-.5v-1.293l-.646.647a.5.5 0 0 1-.707-.708L7.5 12.793V8.866l-3.4 1.963-.496 1.85a.5.5 0 1 1-.966-.26l.237-.882-1.12.646a.5.5 0 0 1-.5-.866l1.12-.646-.884-.237a.5.5 0 1 1 .26-.966l1.848.495L7 8 3.6 6.037l-1.85.495a.5.5 0 0 1-.258-.966l.883-.237-1.12-.646a.5.5 0 1 1 .5-.866l1.12.646-.237-.883a.5.5 0 1 1 .966-.258l.495 1.849L7.5 7.134V3.207L6.147 1.854a.5.5 0 1 1 .707-.708l.646.647V.5a.5.5 0 1 1 1 0v1.293l.647-.647a.5.5 0 1 1 .707.708L8.5 3.207v3.927l3.4-1.963.496-1.85a.5.5 0 1 1 .966.26l-.236.882 1.12-.646a.5.5 0 0 1 .5.866l-1.12.646.883.237a.5.5 0 1 1-.26.966l-1.848-.495L9 8l3.4 1.963 1.849-.495a.5.5 0 0 1 .259.966l-.883.237 1.12.646a.5.5 0 0 1-.5.866l-1.12-.646.236.883a.5.5 0 1 1-.966.258l-.495-1.849-3.4-1.963v3.927l1.353 1.353a.5.5 0 0 1-.707.708l-.647-.647V15.5a.5.5 0 0 1-.5.5z" />
                                        </svg>
                                    </div>
                                    {scStatuses.filter((status) => status.value === user.freeze_member.cycle_first_status)[0].label}
                                    <div className="inline-block ml-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                                            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    className="origin-top-right absolute min-w-min px-6 bg-white focus:outline-none shadow-xl rounded-lg overflow-hidden"
                                    style={scStatusDropdownOpen ? { zIndex: 1000 } : { display: "none" }}
                                >
                                    <div>
                                        {scStatuses.map((status) => {
                                            if (!["fs_2_11", "fz_2_11", "fs_2_5050", "fz_2_5050"].includes(user.freeze_member.cycle_type) && status.value === "frozen_second_cycling") return null;
                                            return (
                                                <div
                                                    key={status.value}
                                                    onClick={() => {
                                                        configureStatusConfirmation(
                                                            user.freeze_member.cycle_first_status,
                                                            status.value,
                                                            scStatuses,
                                                            status.abraUpdates,
                                                            status.iterableEvents,
                                                            "cycle_first_status",
                                                            setSCStatusDropdownOpen
                                                        );
                                                    }}
                                                    className={`px-2 py-1 rounded-md font-medium text-xs my-2`}
                                                    style={{ backgroundColor: status.bg, color: status.color }}
                                                >
                                                    {status.label}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex gap-1">
                            <div ref={amhStatusRef} className="align-left flex mb-1">
                                <div
                                    onClick={() => setAMHStatusDropdownOpen(!amhStatusDropdownOpen)}
                                    className="h-7 text-secondary px-2 py-1 rounded-md border font-medium text-xs flex items-center"
                                    style={{
                                        backgroundColor: amhStatuses.filter((status) => status.value === user.freeze_member.amh_status)[0].bg,
                                        color: amhStatuses.filter((status) => status.value === user.freeze_member.amh_status)[0].color,
                                    }}
                                >
                                    <div className="inline-block mr-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bar-chart-steps" viewBox="0 0 16 16">
                                            <path d="M.5 0a.5.5 0 0 1 .5.5v15a.5.5 0 0 1-1 0V.5A.5.5 0 0 1 .5 0M2 1.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5zm2 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5zm2 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-6a.5.5 0 0 1-.5-.5zm2 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5z" />
                                        </svg>
                                    </div>
                                    {amhStatuses.filter((status) => status.value === user.freeze_member.amh_status)[0].label}
                                    <div className="inline-block ml-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                                            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    className="origin-top-right absolute min-w-min px-6 bg-white focus:outline-none shadow-xl rounded-lg overflow-hidden"
                                    style={amhStatusDropdownOpen ? { zIndex: 1000 } : { display: "none" }}
                                >
                                    <div>
                                        {amhStatuses.map((status) => (
                                            <div
                                                key={status.value}
                                                onClick={() =>
                                                    configureStatusConfirmation(
                                                        user.freeze_member.amh_status,
                                                        status.value,
                                                        amhStatuses,
                                                        status.abraUpdates,
                                                        status.iterableEvents,
                                                        "amh_status",
                                                        setAMHStatusDropdownOpen
                                                    )
                                                }
                                                className={`px-2 py-1 rounded-md font-medium text-xs my-2`}
                                                style={{ backgroundColor: status.bg, color: status.color }}
                                            >
                                                {status.label}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div ref={callStatusRef} className="align-left flex mb-1">
                                <div
                                    onClick={() => setCallStatusDropdownOpen(!callStatusDropdownOpen)}
                                    className="h-7 text-secondary px-2 py-1 rounded-md border font-medium text-xs flex items-center"
                                    style={{
                                        backgroundColor: callStatuses.filter((status) => status.value === user.freeze_member.call_status)[0].bg,
                                        color: callStatuses.filter((status) => status.value === user.freeze_member.call_status)[0].color,
                                    }}
                                >
                                    <div className="inline-block mr-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-telephone-fill" viewBox="0 0 16 16">
                                            <path
                                                fill-rule="evenodd"
                                                d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"
                                            />
                                        </svg>
                                    </div>
                                    {callStatuses.filter((status) => status.value === user.freeze_member.call_status)[0].label}
                                    <div className="inline-block ml-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                                            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    className="origin-top-right absolute min-w-min px-6 bg-white focus:outline-none shadow-xl rounded-lg overflow-hidden"
                                    style={callStatusDropdownOpen ? { zIndex: 1000 } : { display: "none" }}
                                >
                                    <div>
                                        {callStatuses.map((status) => (
                                            <div
                                                key={status.value}
                                                onClick={() =>
                                                    configureStatusConfirmation(
                                                        user.freeze_member.call_status,
                                                        status.value,
                                                        callStatuses,
                                                        status.abraUpdates,
                                                        status.iterableEvents,
                                                        "call_status",
                                                        setCallStatusDropdownOpen
                                                    )
                                                }
                                                className={`px-2 py-1 rounded-md font-medium text-xs my-2`}
                                                style={{ backgroundColor: status.bg, color: status.color }}
                                            >
                                                {status.label}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div ref={agreementStatusRef} className="align-left flex mb-1">
                                <div
                                    onClick={() => setAgreementStatusDropdownOpen(!agreementStatusDropdownOpen)}
                                    className="h-7 text-secondary px-2 py-1 rounded-md border font-medium text-xs flex items-center"
                                    style={{
                                        backgroundColor: agreementStatuses.filter((status) => status.value === user.freeze_member.agreement_status)[0].bg,
                                        color: agreementStatuses.filter((status) => status.value === user.freeze_member.agreement_status)[0].color,
                                    }}
                                >
                                    <div className="inline-block mr-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-vector-pen" viewBox="0 0 16 16">
                                            <path
                                                fill-rule="evenodd"
                                                d="M10.646.646a.5.5 0 0 1 .708 0l4 4a.5.5 0 0 1 0 .708l-1.902 1.902-.829 3.313a1.5 1.5 0 0 1-1.024 1.073L1.254 14.746 4.358 4.4A1.5 1.5 0 0 1 5.43 3.377l3.313-.828zm-1.8 2.908-3.173.793a.5.5 0 0 0-.358.342l-2.57 8.565 8.567-2.57a.5.5 0 0 0 .34-.357l.794-3.174-3.6-3.6z"
                                            />
                                            <path fill-rule="evenodd" d="M2.832 13.228 8 9a1 1 0 1 0-1-1l-4.228 5.168-.026.086z" />
                                        </svg>
                                    </div>
                                    {agreementStatuses.filter((status) => status.value === user.freeze_member.agreement_status)[0].label}
                                    <div className="inline-block ml-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                                            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                                        </svg>
                                    </div>
                                </div>
                                <div
                                    className="origin-top-right absolute min-w-min px-6 bg-white focus:outline-none shadow-xl rounded-lg overflow-hidden"
                                    style={agreementStatusDropdownOpen ? { zIndex: 1000 } : { display: "none" }}
                                >
                                    <div>
                                        {agreementStatuses.map((status) => (
                                            <div
                                                key={status.value}
                                                onClick={() =>
                                                    configureStatusConfirmation(
                                                        user.freeze_member.agreement_status,
                                                        status.value,
                                                        agreementStatuses,
                                                        status.abraUpdates,
                                                        status.iterableEvents,
                                                        "agreement_status",
                                                        setAgreementStatusDropdownOpen
                                                    )
                                                }
                                                className={`px-2 py-1 rounded-md font-medium text-xs my-2`}
                                                style={{ backgroundColor: status.bg, color: status.color }}
                                            >
                                                {status.label}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex gap-1">
                            {user.migration_status ? (
                                <div className="align-left flex mb-1">
                                    <div
                                        className="h-7 text-secondary px-2 py-1 rounded-md border font-medium text-xs flex items-center"
                                        style={{
                                            backgroundColor: migrationStatuses.filter((status) => status.value === user.migration_status)[0].bg,
                                            color: migrationStatuses.filter((status) => status.value === user.migration_status)[0].color,
                                        }}
                                    >
                                        <div className="inline-block mr-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-person-fill-add" viewBox="0 0 16 16">
                                                <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0m-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                                                <path d="M2 13c0 1 1 1 1 1h5.256A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1 1.544-3.393Q8.844 9.002 8 9c-5 0-6 3-6 4" />
                                            </svg>
                                        </div>
                                        {migrationStatuses.filter((status) => status.value === user.migration_status)[0].label}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>

                    <div className="my-2">
                        <div className="my-1 text-sm flex items-center">
                            <div className="inline-block mr-2 text-gray-400">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone" viewBox="0 0 16 16">
                                    <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                                </svg>
                            </div>
                            {formatPhoneNumber(user.phoneNumber)}
                        </div>
                        <div className="my-1 text-sm flex items-center">
                            <div className="inline-block mr-2 text-gray-400">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
                                </svg>
                            </div>
                            {user.email}
                            <div className="inline-block ml-1 cursor-pointer text-secondary" onClick={() => handleCopy(user.email, setCopiedEmail)}>
                                {copiedEmail ? (
                                    // Filled in clipboard
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-clipboard-check-fill inline-block" viewBox="0 0 16 16">
                                        <path d="M6.5 0A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0zm3 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5z" />
                                        <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1A2.5 2.5 0 0 1 9.5 5h-3A2.5 2.5 0 0 1 4 2.5zm6.854 7.354-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708.708" />
                                    </svg>
                                ) : (
                                    // Empty clipboard
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-clipboard inline-block" viewBox="0 0 16 16">
                                        <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z" />
                                        <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z" />
                                    </svg>
                                )}
                            </div>
                        </div>
                        <div className="my-1 text-sm flex items-center">
                            <div className="inline-block mr-2 text-gray-400">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-vcard" viewBox="0 0 16 16">
                                    <path d="M5 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4m4-2.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5M9 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4A.5.5 0 0 1 9 8m1 2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5" />
                                    <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zM1 4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H8.96q.04-.245.04-.5C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1 1 0 0 1 1 12z" />
                                </svg>
                            </div>
                            {user.id}
                            <div className="inline-block ml-1 cursor-pointer text-secondary" onClick={() => handleCopy(user.id, setCopiedID)}>
                                {copiedID ? (
                                    // Filled in clipboard
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-clipboard-check-fill inline-block" viewBox="0 0 16 16">
                                        <path d="M6.5 0A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0zm3 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5z" />
                                        <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1A2.5 2.5 0 0 1 9.5 5h-3A2.5 2.5 0 0 1 4 2.5zm6.854 7.354-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708.708" />
                                    </svg>
                                ) : (
                                    // Empty clipboard
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-clipboard inline-block" viewBox="0 0 16 16">
                                        <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z" />
                                        <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z" />
                                    </svg>
                                )}
                            </div>
                        </div>
                        <div className="my-1 text-sm flex items-center">
                            <div className="inline-block mr-2 text-gray-400">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt" viewBox="0 0 16 16">
                                    <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10" />
                                    <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                                </svg>
                            </div>
                            {user.address1}, {user.address2 ? `${user.address2},` : ""} {user.city}, {user.state}, {user.zipcode}
                        </div>
                        {appStartedDate ? (
                            <div className="my-1 text-sm flex items-center">
                                <div className="inline-block mr-2 text-gray-400">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-calendar" viewBox="0 0 16 16">
                                        <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
                                    </svg>
                                </div>
                                <span title={extractDate(appStartedDate)}>App started: {calculateLogTime(appStartedDate)}</span>
                            </div>
                        ) : null}
                    </div>

                    <div className="flex items-center">
                        {/* <span onClick={() => setShowContactModal(true)} className="text-secondary text-sm font-normal mr-2 cursor-pointer flex items-center">
                            <div className="inline-block mr-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                                </svg>
                            </div>
                            Edit Contact Info
                        </span>
                        <span className="border-l h-6 mx-1 border-gray-300"></span> */}
                        <span className="text-secondary text-sm font-normal mx-2 cursor-pointer flex items-center">
                            Copper Profile
                            <div className="inline-block ml-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z" />
                                </svg>
                            </div>
                        </span>
                        <span className="border-l h-6 mx-1 border-gray-300"></span>
                        {user.freeze_member.status !== "application_in_progress" ? (
                            <a href={publicProfileHREF()} target="_blank" rel="noreferrer" className="text-secondary text-sm font-normal mx-2 cursor-pointer flex items-center">
                                Public Profile
                                <div className="inline-block ml-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z" />
                                    </svg>
                                </div>
                            </a>
                        ) : null}
                    </div>
                </div>

                <div className="flex">
                    <div ref={taskCenterRef}>
                        <div
                            onClick={() => setShowTaskCenter(!showTaskCenter)}
                            className="mx-3 w-12 h-12 rounded-full px-2 py-1 text-black text-3xl flex items-center border-2 border-solid border-secondary text-secondary"
                            style={{ backgroundColor: "#D2E9E6" }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-card-list" viewBox="0 0 16 16">
                                <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2z" />
                                <path d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8m0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m-1-5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0M4 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0m0 2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0" />
                            </svg>
                        </div>

                        {showTaskCenter ? (
                            <div className="min-h-[300px] min-w-[450px] mx-5 bg-white shadow-md rounded-lg" style={{ position: "absolute", top: 170, right: 290, zIndex: 10 }}>
                                <div className="flex justify-between items-center bg-secondary text-white p-4 rounded-t-lg">
                                    <span>
                                        <h4 className="inline-block">Actions Items</h4>
                                        <span className="inline-block mx-2 text-sm">
                                            (
                                            {currentPhase && currentPhase.checklist_tasks
                                                ? currentPhase.checklist_tasks.reduce((completedCount, task) => {
                                                      if (task.user_checklist_task && task.user_checklist_task.status === "complete") {
                                                          return completedCount + 1;
                                                      }

                                                      return completedCount;
                                                  }, 0)
                                                : 0}{" "}
                                            of {currentPhase && currentPhase.checklist_tasks ? currentPhase.checklist_tasks.length : 0})
                                        </span>
                                    </span>
                                    <span className="cursor-pointer" onClick={() => setShowTaskCenter(!showTaskCenter)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                                        </svg>
                                    </span>
                                </div>

                                <div className={`bg-secondary w-full`}>
                                    <div className="flex flex-row w-full mx-5">
                                        <div
                                            onClick={() => setTaskTab(`now`)}
                                            className={`${
                                                taskTab === "now" ? "border-white font-medium" : "border-transparent"
                                            } text-white border-x-0 px-0 pb-3 border-b-3 text-sm mr-7 cursor-pointer`}
                                        >
                                            NOW
                                        </div>
                                        <div
                                            onClick={() => setTaskTab(`completed`)}
                                            className={`${
                                                taskTab === "completed" ? "border-white font-medium" : "border-transparent"
                                            } text-white border-x-0 px-0 pb-3 border-b-3 text-sm mr-7 cursor-pointer`}
                                        >
                                            COMPLETED
                                        </div>
                                        <div
                                            onClick={() => setTaskTab(`upcoming`)}
                                            className={`${
                                                taskTab === "upcoming" ? "border-white font-medium" : "border-transparent"
                                            } text-white border-x-0 px-0 pb-3 border-b-3 text-sm mr-7 cursor-pointer`}
                                        >
                                            UPCOMING
                                        </div>
                                        {/* <div onClick={() => setTaskTab(`all`)} className={`${taskTab === 'all' ? 'border-white font-medium' : 'border-transparent'} text-white border-x-0 px-0 pb-3 border-b-3 text-sm mr-7 cursor-pointer`}>ALL</div> */}
                                    </div>
                                </div>

                                {taskTab === "now" ? (
                                    <div className="ml-5 pb-5">
                                        {currentPhase && currentPhase.checklist_tasks && currentPhase.checklist_tasks.some((task) => task.user_checklist_task.status !== "complete") ? (
                                            currentPhase.checklist_tasks
                                                .filter((task) => task.user_checklist_task.status === "upcoming")
                                                .map((task) => {
                                                    return (
                                                        <div>
                                                            <div className="flex justify-between items-center m-3">
                                                                <h6 className="font-sans font-semibold">{task.name}</h6>
                                                                <div className={`inline-block cursor-pointer ${task.user_checklist_task.status === "complete" ? "text-secondary" : "text-yellow-500"}`}>
                                                                    <svg
                                                                        onClick={() => completeTask(task.user_checklist_task.id, "complete")}
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="24"
                                                                        height="24"
                                                                        fill="currentColor"
                                                                        className={`bi bi-check2-circle`}
                                                                        viewBox="0 0 16 16"
                                                                    >
                                                                        <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0" />
                                                                        <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z" />
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                            <div className="w-3/4 ml-10 mr-2 bg-gray-100 rounded-lg p-3">
                                                                {task.description.split("\\n").map((t) => (
                                                                    <div className="block">{t}</div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                        ) : (
                                            <div>
                                                <div className="flex justify-center text-secondary p-5 mt-5">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" className="bi bi-hand-thumbs-up-fill" viewBox="0 0 16 16">
                                                        <path d="M6.956 1.745C7.021.81 7.908.087 8.864.325l.261.066c.463.116.874.456 1.012.965.22.816.533 2.511.062 4.51a10 10 0 0 1 .443-.051c.713-.065 1.669-.072 2.516.21.518.173.994.681 1.2 1.273.184.532.16 1.162-.234 1.733q.086.18.138.363c.077.27.113.567.113.856s-.036.586-.113.856c-.039.135-.09.273-.16.404.169.387.107.819-.003 1.148a3.2 3.2 0 0 1-.488.901c.054.152.076.312.076.465 0 .305-.089.625-.253.912C13.1 15.522 12.437 16 11.5 16H8c-.605 0-1.07-.081-1.466-.218a4.8 4.8 0 0 1-.97-.484l-.048-.03c-.504-.307-.999-.609-2.068-.722C2.682 14.464 2 13.846 2 13V9c0-.85.685-1.432 1.357-1.615.849-.232 1.574-.787 2.132-1.41.56-.627.914-1.28 1.039-1.639.199-.575.356-1.539.428-2.59z" />
                                                    </svg>
                                                </div>
                                                <div className="flex justify-center text-sm text-gray-500">No more actions items! Take a break!</div>
                                                <div className="flex justify-center text-sm text-gray-500">You deserve it!</div>
                                            </div>
                                        )}
                                    </div>
                                ) : null}

                                {taskTab === "completed" ? (
                                    <div className="ml-5 pb-5">
                                        {currentPhase && currentPhase.checklist_tasks ? (
                                            currentPhase.checklist_tasks.map((task) => {
                                                if (task.user_checklist_task.status !== "complete") {
                                                    return null;
                                                }

                                                return (
                                                    <div>
                                                        <div className="flex justify-between items-center m-3">
                                                            <h6 className="font-sans font-semibold">{task.name}</h6>
                                                            <div className={`inline-block cursor-pointer ${task.user_checklist_task.status === "complete" ? "text-secondary" : "text-yellow-500"}`}>
                                                                <svg
                                                                    onClick={() => completeTask(task.user_checklist_task.id, "upcoming")}
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="24"
                                                                    height="24"
                                                                    fill="currentColor"
                                                                    className={`bi bi-check2-circle`}
                                                                    viewBox="0 0 16 16"
                                                                >
                                                                    <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0" />
                                                                    <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z" />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <div className="w-3/4 ml-10 mr-2 bg-gray-100 rounded-lg p-3">
                                                            {task.description.split("\\n").map((t) => (
                                                                <div className="block">{t}</div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <div>
                                                <div className="flex justify-center text-secondary p-5 mt-5">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" className="bi bi-hand-thumbs-up-fill" viewBox="0 0 16 16">
                                                        <path d="M6.956 1.745C7.021.81 7.908.087 8.864.325l.261.066c.463.116.874.456 1.012.965.22.816.533 2.511.062 4.51a10 10 0 0 1 .443-.051c.713-.065 1.669-.072 2.516.21.518.173.994.681 1.2 1.273.184.532.16 1.162-.234 1.733q.086.18.138.363c.077.27.113.567.113.856s-.036.586-.113.856c-.039.135-.09.273-.16.404.169.387.107.819-.003 1.148a3.2 3.2 0 0 1-.488.901c.054.152.076.312.076.465 0 .305-.089.625-.253.912C13.1 15.522 12.437 16 11.5 16H8c-.605 0-1.07-.081-1.466-.218a4.8 4.8 0 0 1-.97-.484l-.048-.03c-.504-.307-.999-.609-2.068-.722C2.682 14.464 2 13.846 2 13V9c0-.85.685-1.432 1.357-1.615.849-.232 1.574-.787 2.132-1.41.56-.627.914-1.28 1.039-1.639.199-.575.356-1.539.428-2.59z" />
                                                    </svg>
                                                </div>
                                                <div className="flex justify-center text-sm text-gray-500">No completed items yet!</div>
                                            </div>
                                        )}
                                    </div>
                                ) : null}

                                {taskTab === "upcoming" ? (
                                    <div className="ml-5 pb-5">
                                        {currentPhase && currentPhase.checklist_tasks ? (
                                            currentPhase.checklist_tasks.map((task) => {
                                                if (task.user_checklist_task.status !== "upcoming") {
                                                    return null;
                                                }

                                                return (
                                                    <div>
                                                        <div className="flex justify-between items-center m-3">
                                                            <h6 className="font-sans font-semibold">{task.name}</h6>
                                                            <div className={`inline-block cursor-pointer ${task.user_checklist_task.status === "complete" ? "text-secondary" : "text-yellow-500"}`}>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="24"
                                                                    height="24"
                                                                    fill="currentColor"
                                                                    className={`bi bi-check2-circle`}
                                                                    viewBox="0 0 16 16"
                                                                >
                                                                    <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0" />
                                                                    <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z" />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <div className="w-3/4 ml-10 mr-2 bg-gray-100 rounded-lg p-3">
                                                            {task.description.split("\\n").map((t) => (
                                                                <div className="block">{t}</div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <div>
                                                <div className="flex justify-center text-secondary p-5 mt-5">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" className="bi bi-hand-thumbs-up-fill" viewBox="0 0 16 16">
                                                        <path d="M6.956 1.745C7.021.81 7.908.087 8.864.325l.261.066c.463.116.874.456 1.012.965.22.816.533 2.511.062 4.51a10 10 0 0 1 .443-.051c.713-.065 1.669-.072 2.516.21.518.173.994.681 1.2 1.273.184.532.16 1.162-.234 1.733q.086.18.138.363c.077.27.113.567.113.856s-.036.586-.113.856c-.039.135-.09.273-.16.404.169.387.107.819-.003 1.148a3.2 3.2 0 0 1-.488.901c.054.152.076.312.076.465 0 .305-.089.625-.253.912C13.1 15.522 12.437 16 11.5 16H8c-.605 0-1.07-.081-1.466-.218a4.8 4.8 0 0 1-.97-.484l-.048-.03c-.504-.307-.999-.609-2.068-.722C2.682 14.464 2 13.846 2 13V9c0-.85.685-1.432 1.357-1.615.849-.232 1.574-.787 2.132-1.41.56-.627.914-1.28 1.039-1.639.199-.575.356-1.539.428-2.59z" />
                                                    </svg>
                                                </div>
                                                <div className="flex justify-center text-sm text-gray-500">Nothing coming up! Take a break!</div>
                                                <div className="flex justify-center text-sm text-gray-500">You deserve it!</div>
                                            </div>
                                        )}
                                    </div>
                                ) : null}
                            </div>
                        ) : null}
                    </div>
                    <div>
                        <button className="block h-12 w-40 bg-secondary text-white border border-secondary rounded-full py-2 px-6 font-semibold mb-3" onClick={() => setShowNoteModal(!showNoteModal)}>
                            + Add Note
                        </button>
                        <button className="block h-12 w-40 bg-secondary text-white border border-secondary rounded-full py-2 px-6 font-semibold mb-3" onClick={() => setMatchModal(!matchModal)}>
                            Create Match
                        </button>
                        {!["application_in_progress", "disqualified", "retired"].includes(user.freeze_member.status) ? (
                            <div ref={pdfRef}>
                                <button className="block h-12 w-40 bg-secondary text-white border border-secondary rounded-full py-2 px-6 font-semibold mb-3" onClick={() => setPDFOptions(true)}>
                                    Create PDF
                                </button>
                                {pdfOptions ? (
                                    <div className="w-36 mx-5 bg-white-100 drop-shadow-md shadow-md rounded-lg" style={{ position: "absolute", top: 290, right: 120, zIndex: 10 }}>
                                        <div
                                            onClick={() => {
                                                window.open(window.location.origin + `/staff/split-members/${user.id}/ip-pdf`, "_blank");
                                                setPDFOptions(false);
                                            }}
                                            className="px-4 py-2 font-semibold bg-secondary text-white m-2 rounded-full text-center"
                                        >
                                            IP PDF
                                        </div>
                                        <div
                                            onClick={() => {
                                                window.open(window.location.origin + `/staff/split-members/${user.id}/admin-pdf`, "_blank");
                                                setPDFOptions(false);
                                            }}
                                            className="px-4 py-2 font-semibold bg-secondary text-white m-2 rounded-full text-center"
                                        >
                                            Admin PDF
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>

            <div className={`bg-white divide-y divide-solid w-full pb-5`} ref={tabBarRef}>
                <div className="ml-30 flex flex-row w-1/2 pt-5">
                    <div
                        onClick={() => changeTab(`overview`)}
                        className={`${currentTab === "overview" ? "border-secondary" : "border-transparent"} text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7 cursor-pointer`}
                    >
                        OVERVIEW
                    </div>
                    <div
                        onClick={() => changeTab(`profile`)}
                        className={`${currentTab === "profile" ? "border-secondary" : "border-transparent"} text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7 cursor-pointer`}
                    >
                        PROFILE
                    </div>
                    <div
                        onClick={() => changeTab(`activity`)}
                        className={`${currentTab === "activity" ? "border-secondary" : "border-transparent"} text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7 cursor-pointer`}
                    >
                        ACTIVITY
                    </div>
                    <div
                        onClick={() => changeTab(`documents`)}
                        className={`${currentTab === "documents" ? "border-secondary" : "border-transparent"} text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7 cursor-pointer`}
                    >
                        DOCUMENTS
                    </div>
                    <div
                        onClick={() => changeTab(`notes`)}
                        className={`${currentTab === "notes" ? "border-secondary" : "border-transparent"} text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7 cursor-pointer`}
                    >
                        NOTES
                    </div>
                    <div
                        onClick={() => changeTab(`photos`)}
                        className={`${currentTab === "photos" ? "border-secondary" : "border-transparent"} text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7 cursor-pointer`}
                    >
                        PHOTOS
                    </div>
                    <div
                        onClick={() => changeTab(`logs`)}
                        className={`${currentTab === "logs" ? "border-secondary" : "border-transparent"} text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7 cursor-pointer`}
                    >
                        LOGS
                    </div>
                    {user.freeze_member.status !== "disqualified" && user.freeze_member.status !== "application_in_progress" ? (
                        <div
                            onClick={() => changeTab(`checklist`)}
                            className={`${
                                currentTab === "checklist" ? "border-secondary" : "border-transparent"
                            } text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7 cursor-pointer`}
                        >
                            CHECKLIST
                        </div>
                    ) : null}
                </div>

                <div></div>
            </div>

            {stickyTabs ? (
                <div className={`bg-white divide-y divide-solid w-full`} style={{ position: "fixed", top: 0, zIndex: 10 }}>
                    <div className="ml-30 flex flex-row w-1/2 pt-5">
                        <div
                            onClick={() => changeTab(`overview`)}
                            className={`${
                                currentTab === "overview" ? "border-secondary" : "border-transparent"
                            } text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7 cursor-pointer`}
                        >
                            OVERVIEW
                        </div>
                        <div
                            onClick={() => changeTab(`profile`)}
                            className={`${currentTab === "profile" ? "border-secondary" : "border-transparent"} text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7 cursor-pointer`}
                        >
                            PROFILE
                        </div>
                        <div
                            onClick={() => changeTab(`activity`)}
                            className={`${
                                currentTab === "activity" ? "border-secondary" : "border-transparent"
                            } text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7 cursor-pointer`}
                        >
                            ACTIVITY
                        </div>
                        <div
                            onClick={() => changeTab(`documents`)}
                            className={`${
                                currentTab === "documents" ? "border-secondary" : "border-transparent"
                            } text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7 cursor-pointer`}
                        >
                            DOCUMENTS
                        </div>
                        <div
                            onClick={() => changeTab(`notes`)}
                            className={`${currentTab === "notes" ? "border-secondary" : "border-transparent"} text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7 cursor-pointer`}
                        >
                            NOTES
                        </div>
                        <div
                            onClick={() => changeTab(`photos`)}
                            className={`${currentTab === "photos" ? "border-secondary" : "border-transparent"} text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7 cursor-pointer`}
                        >
                            PHOTOS
                        </div>
                        <div
                            onClick={() => changeTab(`logs`)}
                            className={`${currentTab === "logs" ? "border-secondary" : "border-transparent"} text-secondary border-x-0 px-0 pb-3 border-b-3 text-sm font-medium mr-7 cursor-pointer`}
                        >
                            LOGS
                        </div>
                    </div>

                    <div></div>
                </div>
            ) : null}

            {matchModal ? <CreateMatchModal sm={user} closeModal={() => setMatchModal(false)} /> : null}

            {disqualModal ? (
                <div
                    className="modal"
                    style={{
                        position: "fixed",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 10,
                    }}
                >
                    <div
                        className="modal-content w-full max-w-3xl"
                        style={{
                            backgroundColor: "white",
                            padding: "40px",
                            borderRadius: "5px",
                            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.3)",
                        }}
                    >
                        <h3 className="text-2xl font-semibold mb-4 flex justify-between items-center">
                            <span className="heading-underline-forest-50 ">Disqualification Reason</span>
                        </h3>
                        <div className="mb-5">
                            <div className="font-semibold">Abra updates</div>
                            <div>- Enrolls user in Keep program and changes checklist to Keep</div>
                            <div>- Activates 'Complete our Keep intake form' task</div>
                            <div>- Activates 'Complete your consult and plan for your egg retrieval cycle' task</div>
                            <div>- Activates 'Transfer your eggs to long-term storage' task</div>
                        </div>
                        <div ref={disqualDropdownRef} className={`relative flex justify-between p-2 w-3/4 bg-white rounded-lg ${filterFocus ? "ring-2 ring-blue-700" : "border-black border"}`}>
                            <input
                                value={disqualSearchInput}
                                onFocus={() => {
                                    setFilterFocus(true);
                                    setDisqualSearchInput("");
                                }}
                                className="w-3/4 focus:outline-none focus:ring-0"
                                onChange={(event) => handleInputChange(event, setDisqualSearchInput)}
                                placeholder={"Search for disqual reason..."}
                            ></input>
                            {filterFocus ? (
                                <div className="absolute mt-10 w-full py-2 left-0 bg-white rounded-md shadow-lg overflow-y-auto max-h-[300px] divide-y divide-dashed" style={{ zIndex: 1000 }}>
                                    {DisqualificatinReasons.map((option) => {
                                        if (option.label.toLowerCase().includes(disqualSearchInput.toLowerCase())) {
                                            return (
                                                <div
                                                    onClick={() => {
                                                        setDisqualSearchInput("");
                                                        updateDisqualReason(option.slug);
                                                        setFilterFocus(false);
                                                    }}
                                                    key={option.slug}
                                                    className={`ml-2 p-1`}
                                                >
                                                    {option.label}
                                                </div>
                                            );
                                        }

                                        return null;
                                    })}
                                </div>
                            ) : null}
                        </div>
                        {disqualReasons.length ? (
                            <div className="mt-2">
                                {disqualReasons.map((option) => (
                                    <div
                                        key={option}
                                        className={`px-2 py-1 rounded-md font-medium text-xs mx-1 w-fit flex items-center my-1`}
                                        style={{ backgroundColor: "#D2E9E6", color: "secondary" }}
                                    >
                                        <span>{option}</span>
                                        <div onClick={() => updateDisqualReason(option)} className="ml-1 inline-block">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x cursor-pointer" viewBox="0 0 16 16">
                                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                                            </svg>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : null}
                        <div className="grid grid-cols-2 gap-x-6 gap-y-4 rounded-md p-4">
                            <div className="col-span-2 flex justify-end mt-4">
                                <button
                                    onClick={() => {
                                        user.freeze_member.disqual_reason ? setDisqualReasons(user.freeze_member.disqual_reason.split(";")) : setDisqualReasons([]);
                                        setDisqualSearchInput("");
                                        setDisqualModal(false);
                                    }}
                                    className="mr-2 px-4 py-2 rounded-md bg-gray-300 hover:bg-gray-400"
                                >
                                    Close
                                </button>
                                <button onClick={setDisqualification} className="px-4 py-2 rounded-md bg-blue-500 hover:bg-blue-600 text-white">
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}

            {showNoteModal ? (
                <SimpleModal
                    title="Staff Note"
                    inputs={[{ textArea: true, value: noteText, setInputState: setNoteText, handleChange: handleNoteChange, placeholder: "Type your note here..." }]}
                    close={closeNote}
                    save={saveNote}
                    leftHeaders={[
                        <button onClick={() => setToggleNotePinned(!toggleNotePinned)} className="focus:outline-none">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className={`h-6 w-6 fill-current ${toggleNotePinned ? "text-yellow-600" : "text-gray-500"} hover:text-yellow-600`}
                                viewBox="0 0 24 24"
                            >
                                <path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z" />
                            </svg>
                        </button>,
                    ]}
                />
            ) : null}

            {confirmInvite ? <SimpleModal title="Confirm send beta invite" close={() => setConfirmInvite(false)} saveText="Confirm" save={sendAbraInvite} /> : null}

            {statusConfirmationFunc ? (
                <SimpleModal
                    title="Status change confirmation"
                    bodyText={statusConfirmationText}
                    inputs={
                        pendingStatus === "active"
                            ? [
                                  {
                                      title: "Add to new donor digest",
                                      type: "checkbox",
                                      setInputState: setNewDonorDigest,
                                      value: newDonorDigest,
                                  },
                              ]
                            : null
                    }
                    close={() => {
                        setPendingStatus(null);
                        setNewDonorDigest(false);
                        setStatusConfirmationFunc(null);
                        setStatusConfirmationText(null);
                    }}
                    save={() => statusConfirmationFunc(newDonorDigest)}
                />
            ) : null}

            {showContactModal ? (
                <div
                    className="modal"
                    style={{
                        position: "fixed",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 10,
                    }}
                >
                    <div
                        className="modal-content w-full max-w-3xl"
                        style={{
                            backgroundColor: "white",
                            padding: "40px",
                            borderRadius: "5px",
                            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.3)",
                        }}
                    >
                        <h3 className="text-2xl font-semibold mb-4">
                            <span className="heading-underline-forest-50 mr-5">Edit Contact Details</span>
                        </h3>
                        <div className="grid grid-cols-2 gap-x-6 gap-y-4 rounded-md p-4">
                            <div>
                                <label className="text-sm font-medium">First name:</label>
                                <input className="form-input mt-1 w-full border border-gray-300 rounded-lg px-2" onChange={(event) => handleInputChange(event, setFirstName)} value={firstName} />
                            </div>

                            <div>
                                <label className="text-sm font-medium">Last name: </label>
                                <input className="form-input mt-1 w-full border border-gray-300 rounded-lg px-2" onChange={(event) => handleInputChange(event, setLastName)} value={lastName} />
                            </div>

                            <div>
                                <label className="text-sm font-medium">Preferred name: </label>
                                <input
                                    className="form-input mt-1 w-full border border-gray-300 rounded-lg px-2"
                                    onChange={(event) => handleInputChange(event, setPreferredName)}
                                    value={preferredName}
                                />
                            </div>

                            <div>
                                <label className="text-sm font-medium">Pronouns: </label>
                                <input className="form-input mt-1 w-full border border-gray-300 rounded-lg px-2" onChange={(event) => handleInputChange(event, setPronouns)} value={pronouns} />
                            </div>

                            <div>
                                <label className="text-sm font-medium">Phone number: </label>
                                <input className="form-input mt-1 w-full border border-gray-300 rounded-lg px-2" onChange={(event) => handleInputChange(event, setPhone)} value={phone} />
                            </div>

                            <div>
                                <label className="text-sm font-medium">Email: </label>
                                <input className="form-input mt-1 w-full border border-gray-300 rounded-lg px-2" onChange={(event) => handleInputChange(event, setEmail)} value={email} />
                            </div>

                            <div>
                                <label className="text-sm font-medium">Address 1: </label>
                                <input className="form-input mt-1 w-full border border-gray-300 rounded-lg px-2" onChange={(event) => handleInputChange(event, setAddress1)} value={address1} />
                            </div>

                            <div>
                                <label className="text-sm font-medium">Address 2: </label>
                                <input className="form-input mt-1 w-full border border-gray-300 rounded-lg px-2" onChange={(event) => handleInputChange(event, setAddress2)} value={address2} />
                            </div>

                            <div>
                                <label className="text-sm font-medium">City: </label>
                                <input className="form-input mt-1 w-full border border-gray-300 rounded-lg px-2" onChange={(event) => handleInputChange(event, setCity)} value={city} />
                            </div>

                            <div>
                                <label className="text-sm font-medium">State: </label>
                                <input className="form-input mt-1 w-full border border-gray-300 rounded-lg px-2" onChange={(event) => handleInputChange(event, setHomeState)} value={homeState} />
                            </div>

                            <div>
                                <label className="text-sm font-medium">Country: </label>
                                <input className="form-input mt-1 w-full border border-gray-300 rounded-lg px-2" onChange={(event) => handleInputChange(event, setCountry)} value={country} />
                            </div>

                            <div>
                                <label className="text-sm font-medium">Zipcode: </label>
                                <input className="form-input mt-1 w-full border border-gray-300 rounded-lg px-2" onChange={(event) => handleInputChange(event, setZipcode)} value={zipcode} />
                            </div>

                            <div className="col-span-2 flex justify-end mt-4">
                                <button onClick={closeContactModal} className="mr-2 px-4 py-2 rounded-md bg-gray-300 hover:bg-gray-400">
                                    Close
                                </button>
                                <button onClick={saveContactEdits} className="px-4 py-2 rounded-md bg-blue-500 hover:bg-blue-600 text-white">
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}

            <div className="min-h-screen">
                {currentTab === "overview" ? <Overview key={refreshKey} userId={userId} refreshHeader={refetchUser} setRefreshHeader={setRefetchUser} setShowNoteModal={setShowNoteModal} /> : null}

                {currentTab === "profile" ? <Profile user={user} /> : null}

                {currentTab === "activity" ? <Activity user={user} tab={subtab ? subtab : "favorites"} /> : null}

                {currentTab === "documents" ? <Documents user={user} staff={true} userType={"split-members"} /> : null}

                {currentTab === "notes" ? <Notes key={refreshKey} user={user} userType={"split-members"} /> : null}

                {currentTab === "photos" ? <UploadGallery user={user} userType={"split-members"} setRefreshHeader={setRefetchUser} staff={true} /> : null}

                {currentTab === "logs" ? <AuditLogs key={refreshKey} user={user} userType={"split-members"} /> : null}

                {currentTab === "checklist" ? <SMAdminChecklist key={refreshKey} user={user} /> : null}
            </div>
        </div>
    );
};

export default SplitMemberPage;
